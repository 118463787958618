export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const bill = require("../assets/bill.png");
export const bill_active = require("../assets/bill_active.png");
export const plan = require("../assets/plan.png");
export const plan_active = require("../assets/plan_active.png");
export const invoice = require("../assets/invoice.png");
export const invoice_active = require("../assets/invoice_active.png");
export const check = require("../assets/check.png");
export const tick = require("../assets/tick.png");
export const subscibed = require("../assets/subscribed.png");
export const download = require("../assets/download.png");
export const pdfimage = require("../assets/pdfimage.png");
export const icon = require("../assets/icon.png");
export const trash = require("../assets/trash.png");
export const fail = require("../assets/fail.png");
export const success = require("../assets/success.png");
export const sadFace = require("../assets/sad_face.svg");
export const popup = require("../assets/popup.png");
export const happyLady = require("../assets/happy_lady.png");
