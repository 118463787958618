Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GanttChart3";
exports.labelBodyText = "GanttChart3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.ganttChartOptions = {
  chart: {
    height: 400,
    type: "rangeBar",
    offsetY: 30,
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val, { seriesIndex }) {
      return seriesIndex === 0 ? "Planned" : "Actual"; // Display "Planned" or "Actual" based on series
    },
    style: {
      colors: ["#000"], // Label text color
      fontSize: "12px",
    },
  },
  fill: {
    colors: ["#D3D3D3", "#6EEA95"],
  },
  xaxis: {
    type: "datetime",
    position: "top",
  },
  legend: {
    show: false,
  },
  annotations: {
    xaxis: [
      {
        x: new Date("6 Dec 2024").getTime(),
        borderColor: "#43D270",
        strokeDashArray: 0,
        label: {
          text: "Today",
          orientation: "horizontal",
          offsetX: 19,
          style: {
            background: "#43D270",
            color: "#34373A",
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Lato",
          },
        },
      },
    ],
  },
};
// Customizable Area End