import React from "react";

// Customizable Area Start
import { Typography, Box } from "@material-ui/core";
import { success, fail } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props,
} from "./CustomisableusersubscriptionsController";

export default class PaymentStatus extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getPlans();
        const search = new URLSearchParams(window.location.search);
        const sessionId = search.get("session_id");
        this.setState({ sessionId });
        this.handleDelete();
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const status = this.props.navigation.getParam("status")

        return (
            <>
                {
                    status === "success" ?
                        <Box style={webStyle.container as React.CSSProperties}>
                            <Typography style={webStyle.backtext as React.CSSProperties}>Thank You</Typography>
                            <Box style={webStyle.Subcontainer as React.CSSProperties}>
                                <img src={success} alt="success" width={228} height={228} />
                                <Typography style={webStyle.title as React.CSSProperties}>Your Payment is Successful</Typography>
                                <Typography style={webStyle.Description as React.CSSProperties}>
                                    Thank you for your payment. An automated payment receipt will be
                                    sent to your registered email. Alternatively you can download the invoice from
                                    the invoice section in the settings later.
                                </Typography>
                                <Link to="/settings/plan&billing/select-plan" style={webStyle.Link as React.CSSProperties}>Take me Home</Link>
                            </Box>
                        </Box> :
                        <Box style={webStyle.container as React.CSSProperties}>
                            <Typography style={webStyle.backtext as React.CSSProperties}>Try Again</Typography>
                            <Box style={webStyle.Subcontainer as React.CSSProperties}>
                                <img src={fail} alt="success" width={228} height={228} />
                                <Typography style={webStyle.title as React.CSSProperties}>Your Payment is Unsuccessful</Typography>
                                <Typography style={webStyle.Description as React.CSSProperties}>
                                    Something went wrong. Your payment was not processed. Please click the
                                    below link to try again. Once payment is successful we will email
                                    you the invoice.
                                </Typography>
                                <Link to="/settings/plan&billing/select-plan" style={webStyle.Link as React.CSSProperties}>Take me back</Link>
                            </Box>
                        </Box>
                }
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
        marginTop: '132px'
    },
    Subcontainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
        marginTop: '-60px'
    },
    backtext: {
        display: "flex",
        width: "1102px",
        height: "178px",
        flexDirection: "column",
        justifyContent: "center",
        color: "rgba(0, 0, 0, 0.05)",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat",
        fontSize: "150px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
    },
    title: {
        color: "#34373A",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
        width: "818px",
        marginTop: '45px'
    },
    Description: {
        color: "#34373A",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        width: "818px",
        marginTop: '17px'
    },
    Link: {
        color: "#329E54",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        width: "818px",
        marginTop: '30px'
    }
}
// Customizable Area End
