import React from "react";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import { Slider, Typography, Tooltip, Grid, Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(3),
    },
    customTooltip: {
      backgroundColor: "#ECFBF1",
      color: "#34373A",
      border: "1px solid #43D270",
      borderRadius: "8px",
      fontSize: "0.9rem",
      width: "34px",
      height: "29px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    customArrow: {
      color: "#ECFBF1",
      "&::before": {
        border: "1px solid #43D270",
      },
    },
  })
);

interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: ValueLabelComponentProps) {
  const { children, open, value } = props;
  const paddedValue = String(value).padStart(2, '0');
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={paddedValue}
      classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
    >
      {children}
    </Tooltip>
  );
}

const CustomSlider = withStyles({
  root: {
    color: "#43D270",
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#43D270",
    border: "2px solid #fff",
    marginTop: -5,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    "&$disabled": {
      height: 20,
      width: 20,
      backgroundColor: "#43D270",
      border: "2px solid #fff",
      marginTop: -5,
      marginLeft: -12,
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 10,
    borderRadius: 8,
  },
  rail: {
    height: 10,
    borderRadius: 8,
    backgroundColor: "#ECFBF1",
    "&$disabled": {
      backgroundColor: "#555 !important",
    },
  },
  disabled: {
    color: "#9e9e9e",
  },
})(Slider);

const ScoreBox = styled(Box)({
  width: "50px",
  height: "48px",
  padding: "12px 16px 12px 16px",
  borderRadius: "8px",
  border: "1px solid #43D270",
  fontFamily: "Lato",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "24px"
});

const Label = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#34373A",
  marginBottom: "10px"
});

const ErrorMessage = styled(Typography)({
  color: "#ff0000",
  fontSize: "15px",
  marginTop: "10px",
  fontFamily: "Lato",
});

const MinMaxScore = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#7D7D7F"
});

interface Props {
  label: string;
  name: string;
  value: number | number[];
  disabled: boolean;
  error: boolean;
  onChange: (name: string, newValue: number | number[]) => void;
}

export default function StyledSlider(props: Props) {
  const score = String(props.value).padStart(2, '0');
  const showErrorMessage = props.error && props.value === 0;

  return (
    <React.Fragment data-test-id="slider-container">
      <Label>{props.label}</Label>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <MinMaxScore>0</MinMaxScore>
        </Grid>
        <Grid item xs>
          <CustomSlider
            min={0}
            max={10}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
            onChange={(event: any, newValue: number | number[]) => {
              props.onChange(props.name, newValue);
            }}
            valueLabelDisplay="auto"
            aria-label={`${props.name}`}
            ValueLabelComponent={ValueLabelComponent}
            data-test-id="custom-slider"
          />
        </Grid>
        <Grid item>
          <MinMaxScore>10</MinMaxScore>
        </Grid>
        <Grid item>
          <ScoreBox style={{
            borderColor: props.disabled ? "#D9D9D9" : "#43D270",
            color: props.disabled ? "#B1B3C3" : "#34373A"
          }}>{score}</ScoreBox>
        </Grid>
      </Grid>
      {showErrorMessage && <ErrorMessage>This is a mandatory field.</ErrorMessage>}
    </React.Fragment>
  );
}