export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Actionplans = require("../assets/ActionPlan.png");
export const Framework = require("../assets/Framework.png");
export const Integrations = require("../assets/Integration.png");
export const reports = require("../assets/reports.png");
export const repot_active = require("../assets/repot_active.png");
export const framwork_active = require("../assets/framwork_active.png");
export const framworkicon = require("../assets/framworkicon.png");
export const integration_active = require("../assets/integration_icon_active.png");
export const integration_icon = require("../assets/integration_icon.png");
export const vendor = require("../assets/vendor.png");
export const vendor_active = require("../assets/vendor_active.png");
export const info = require("../assets/info.png");
export const selected_unsdg = require("../assets/selected_unsdg.svg");
export const selected_unsdg_active = require("../assets/selected_unsdg_active.svg");
export const no_goals = require("../assets/no_goals.png");
export const materiality = require("../assets/materiality.png");
export const materialityActive = require("../assets/materiality_active.png");


