import React from "react";
import { styled, } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Select,
  FormControl,
  MenuItem, 
} from "@material-ui/core";

const ArrowIcon = styled(ExpandMoreIcon)({
  cursor: 'pointer',
  fontSize: '20px !important',
  height: '20px',
  color: '#000'
});

const CustomFormControl = styled(FormControl)({
  borderRadius: '8px',
  width: "fit-content",
  maxWidth: "150px !important",
  border: '1px solid rgba(0, 0, 0, 0.2)',
});

const CustomSelect = styled(Select)({
  height: "36px",
  borderRadius: "8px",
  padding: "8px !important;",
  "& *": {
      background: "#fff !important;",
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22.4px",
      color: '#34373A',
      paddingRight: '0px !important'
  },
  '&:focus': {
      borderColor: '#cccccc',
  },
})

const CustomMenuItem = styled(MenuItem)({
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#606060",
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

interface Option {
  id: number;
  name: string;
}

interface Props {
  value: string;
  onChange: any;
  options: Option[];
  width: string;
}

export default function CustomDropDown(props: Props) {
  return (
    <CustomFormControl 
      data-test-id="custom-dropdown"
      style={{ width: props.width }}
    >
      <CustomSelect
        displayEmpty
        data-test-id="custom-menu"
        disableUnderline
        IconComponent={ArrowIcon}
        value={props.value}
        onChange={props.onChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          PaperProps: {
            style: {
              maxHeight: 200,
              width: "fit-content",
              maxWidth: "100%",
            },
          },
        }}
      >
        {props.options.map((option) => (
          <CustomMenuItem
            data-test-id="custom-menu-item"
            value={option.id}
            key={option.id}
          >
            {option.name}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </CustomFormControl>
  );
}