import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { StepIconProps } from "@material-ui/core/StepIcon";

interface CustomizedSteppersProps {
  getSteps:string[]
  getStepContent:(step:number) => void
  activeStep:number
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#43D270"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

const useQontoStepIconStyles = makeStyles({
  root: {
    display: "flex",
    height: 36,
    width: 36,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid #8C8C8C`,
    backgroundColor: "#fff",
    color: '#8C8C8C',
    fontWeight: 500,
  },
  active: {
    border: `2px solid #43D270`,
    color: '#34373A',
  },
  completed: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed, icon  } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
     {completed ? <Check/> : icon}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      '& .MuiStepper-root':{
        padding:'0',

      }
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepLabel:{
      '&.MuiStepLabel-label':{
        fontSize:'14px',
        fontFamily:'Lato',
        fontWeight:600
      }
    }
  })
);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    "& $line": {
      background: "#43D270",
    },
  },
  completed: {
    "& $line": {
      background: "#43D270",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

export default function CustomizedSteppers(props:CustomizedSteppersProps) {
  const classes = useStyles();
  const steps = props.getSteps;

  return (
    <ThemeProvider theme={theme}>
    <div className={clsx(classes.root)} >
      <Stepper activeStep={props.activeStep} connector={<ColorlibConnector />} style={{padding:' 24px 0'}}>
        {steps.map((label,index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon} className={classes.stepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
    </ThemeProvider>
  );
}
