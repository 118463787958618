import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import InvoicePDF from "../../../components/src/Invoicepdf.web";
import React from "react";
import html2canvas from 'html2canvas';
import { Token } from 'react-stripe-checkout';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";

export interface Plan {
  id: number
  level: string
  subscribed: boolean
  disabled: boolean
  amount?: number
  amount_yearly?: number
  subscription_type: string
  currency_type: string
  billing_period: string
  pay_time: string
  selected_pricing_features: SelectedPricingFeature[]
  start_date: string
  end_date: string
}

interface SelectedPricingFeature {
  name: string
  status: boolean
}

interface Invoice {
  id: number
  invoice_code: string
  invoice_date: string
  start_date: string
  end_date: string
  plan_name: string
  quantity: number
  amount: number
  total:number
  total_due:number
  sub_total:number
  discount:number
  company_address: string | null
  currency_type: string
  currency_symbol: string
  contact_place:string
  contact_address:string
  contact_phoneno:string
  contact_email:string
  contact_website:string | null
  plan_features:string[]
}
interface Planid {
  id: number
  type: string
}

interface ContactInfo1 {
  contact_phone:string
  contact_email:string
  contact_address:string
  contact_address_link:string
  main_company_name:string
  main_contact_address:string
}

interface StripeCheckoutUrlResponse {
  checkout_url: string;
  success: boolean;
  errors: [
    {
      token: string;
    }
  ]
}

interface HighestTierPlanResponse {
  success: boolean;
  higher_plan_name: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route?: any;
  data?: any;
  stripe?: any;
  elements?: any;
  showMonthYearToggle?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: Plan[];
  data: any;
  filter: boolean;
  invoices: Invoice[];
  PlanInfo: Plan | null,
  type: string,
  expanded: number | null,
  perPage: number;
  page: number;
  totalUser: number;
  isShowInvoice: boolean;
  invoiceData: any;
  showDowngradeSubscriptionAlert: boolean;
  showAmountAlert: boolean;
  level: string;
  contactInfo: ContactInfo1;
  sessionId: string | null;
  showUpdateAddressModal: boolean;
  highestTierPlanName: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getplansCallId: any;
  getInvoiceApiId: any;
  subscribeCallId: any;
  stripeCheckoutUrlApiCallId: string = "";
  stripePaymentSuccessApiCallId: string = "";
  stripePaymentFailureApiCallId: string = "";
  getPlanDataApiCallId: string = "";
  account_id: number;
  planid: Planid;
  account_type: string = "";
  organizationid: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      filter: true,
      invoices: [],
      PlanInfo: null,
      type: "monthly",
      expanded: null,
      perPage: 5,
      page: 1,
      totalUser: 0,
      isShowInvoice: false,
      invoiceData: {},
      showDowngradeSubscriptionAlert: false,
      showAmountAlert: false,
      level: '',
      contactInfo:{
        contact_phone: "",
        contact_email: "",
        contact_address: "",
        contact_address_link: "",
        main_company_name: "",
        main_contact_address: ""
      },
      sessionId: "",
      showUpdateAddressModal: false,
      highestTierPlanName: "",
    };
    this.account_id = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.id
    this.planid = JSON.parse(localStorage.getItem("plan") as string)
    this.account_type = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.user_account_type
    this.organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleReceve(responseJson, apiRequestCallId)

    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const paymentStatus = this.props.navigation.getParam("status");
    if (
      paymentStatus === "success" &&
      this.state.sessionId &&
      this.state.subscriptions.length > 0
    ) {
      this.onPaymentSuccess();
    } else if (
      paymentStatus === "fail" &&
      this.state.sessionId &&
      this.state.subscriptions.length > 0
    ) {
      this.onPaymentFailure();
    }
  }

  handleReceve(responseJson: any, apiRequestCallId: any) {
    switch (apiRequestCallId) {
      case this.getplansCallId:
        if (responseJson.success) {
          this.setState({
            subscriptions: responseJson.plans
          }, () => {
            if (this.planid && responseJson.plans) {
              this.getPlanData()
            }
          });
        } else if (responseJson.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responseJson.errors[0].token);
        }
        break;
      case this.subscribeCallId:
        if (responseJson.success) {
          localStorage.removeItem("plan")
          this.props.navigation.navigate("PaymentStatus", {
            status: "success"
          })
        } else if (responseJson.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responseJson.errors[0].token);
        } else {
          this.props.navigation.navigate("PaymentStatus", {
            status: "fail"
          })
        }
        break;
      case this.getInvoiceApiId:
        if (responseJson.success) {
          this.setState({
            invoices: responseJson.invoices, totalUser: responseJson.count, contactInfo: responseJson.contact_infos
          });
        } else if (responseJson.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responseJson.errors[0].token);
          return;
        }
        break;
      case this.stripeCheckoutUrlApiCallId:
        this.handleStripeCheckoutUrl(responseJson);
        break;
      case this.stripePaymentSuccessApiCallId:
        this.handlePaymentSuccessResponse(responseJson);
        break;
      case this.stripePaymentFailureApiCallId:
        this.handlePaymentFailureResponse(responseJson);
        break;
      case this.getPlanDataApiCallId:
        this.handleGetHighestTierPlanData(responseJson);
        break;
    }
  }

  handleChange = () => {
    this.setState({ filter: !this.state.filter })
  };

  getPlans = async () => {

    const header = {
      "Content-Type": configJSON.subscriptionApiContentType,
      "token": await getStorageData("authToken")
    };

    let httpBody = {
      "account_id": this.account_id,
    } as { company_id?: number };

    if(this.account_type === "portfolio_manager"){
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getplansCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plansapiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubscribe = (id: number, subsriptionNotPossible: boolean, amount: any, level: string) => {
    if (subsriptionNotPossible) {
      this.setState({
        showDowngradeSubscriptionAlert: true
      });
      return;
    }
    if (amount === null) {
      this.setState({
        showAmountAlert: true,
        level: level
      });
      return;
    }

    const data = this.state.subscriptions.find((item) => item.id === id)
    const plandata = {
      id: data?.id,
      type: this.state.filter ? "yearly" : "monthly"
    }
    localStorage.setItem("plan", JSON.stringify(plandata))
    this.props.navigation.navigate("PlanAndBilling", {
      orgTab: "confirm-billing"
    });
  }

  handleCloseDowngradeSubscriptionAlert = () => {
    this.setState({
      showDowngradeSubscriptionAlert: false
    });
  }

  handleCloseAmountAlert = () => {
    this.setState({
      showAmountAlert: false
    });
  }

  getPlanData() {
    const findPlan = this.state.subscriptions.find((item) => item.id === this.planid.id) as Plan
    this.setState({ PlanInfo: findPlan, type: this.planid.type })
  }

  handleToken = async (token: Token) => {

    const findPlan = this.state.subscriptions.find((item: Plan) => item.id === this.planid.id) as Plan

    const header = {
      "Content-Type": configJSON.subscriptionApiContentType,
      "token": await getStorageData("authToken")
    };

    const httpBody = {
      "account_id": this.account_id,
      "plan_id": this.planid.id,
      "user_subscription_type": this.planid.type,
      "amount": this.planid.type === "monthly" ? findPlan?.amount ?? 0 * 100 : findPlan?.amount_yearly ?? 0 * 100,
      "stripe_token": token.id
    } as { company_id?: number };


    if(this.account_type === "portfolio_manager"){
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subscribeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscribeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInvoices = async () => {

    const header = {
      "Content-Type": configJSON.subscriptionApiContentType,
      "token": await getStorageData("authToken")
    };

    const httpBody = {
      "account_id": this.account_id,
      "page_no": this.state.page,
      "items_per_page": this.state.perPage
    } as { company_id?: number };

  
    if(this.account_type === "portfolio_manager"){
      httpBody.company_id = this.organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInvoiceApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SubscribeapiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleGeneratePdf = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');

    const modalContent = document.getElementById('modalContent') as any;

    html2canvas(modalContent, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
      pdf.save('invoice.pdf');
    });

  };

  handleDelete = () => {
    localStorage.removeItem("plan")
    this.setState({ PlanInfo: null })
  }

  getamount() {
    const { type, PlanInfo } = this.state
    if (type === "monthly") {
      return PlanInfo?.amount?.toFixed(2) ?? ""
    } else {
      return PlanInfo?.amount_yearly?.toFixed(2) ?? ""
    }
  }

  getamountStripe() {
    const { type, PlanInfo } = this.state
    if (type === "monthly") {
      return Number(PlanInfo?.amount) * 100
    } else {
      return Number(PlanInfo?.amount_yearly) * 100
    }
  }

  handleChangeAccordian = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : null });
  };

  checkSubscription() {
    return this.state.filter ? "yearly" : "monthly"
  }

  handleTablePageChange = (e: any, page: number) => {
    this.setState({ page }, () => {
      this.getInvoices()
    })
  }

  handleTableNumberChange = (e: any,) => {
    this.setState({ perPage: e.target.value, page: 1 }, () => {
      this.getInvoices()
    })
  }

  handleCloseInvoice = () => {
    this.setState({
      isShowInvoice: false
    })
  }

  handleShowInvoice = (invoice: Invoice) => {
    if (invoice.company_address === null) {
      this.setState({
        showUpdateAddressModal: true,
      });
      return;
    }

    this.setState({
      isShowInvoice: true, invoiceData: invoice
    })
  }

  logoutUser = () => {
    localStorage.clear();
    window.open("/login/primary", "_self");
  }

  saveTokenAlert = (message: string) => {
    const alert = {
      message,
      isOpen: true,
    }

    setStorageData("tokenAlert", JSON.stringify(alert));
  }

  onCheckoutClick = async () => {
    const selectedPlan = this.state.subscriptions.find((item: Plan) => item.id === this.planid.id) as Plan;

    if (selectedPlan) {
      const payload = {
        "account_id": this.account_id,
        "plan_id": this.planid.id,
        "user_subscription_type": this.planid.type,
        "amount": this.planid.type === "monthly" ? selectedPlan.amount : selectedPlan.amount_yearly,
        "success_url": `https://${window.location.host}/payment/success`,
        "failure_url": `https://${window.location.host}/payment/fail`
      }
      this.stripeCheckoutUrlApiCallId = await apiCall({
        contentType: configJSON.subscriptionApiContentType,
        method: configJSON.postAPiMethod,
        endPoint: configJSON.stripeCheckoutUrlEndPoint,
        payload: JSON.stringify(payload),
      });
    }
  }

  handleStripeCheckoutUrl = (responseJson: StripeCheckoutUrlResponse) => {
    if (responseJson.errors?.[0]?.token) {
      this.logoutUser();
      this.saveTokenAlert(responseJson.errors[0].token);
      return;
    }

    if (responseJson.success) {
      window.open(responseJson.checkout_url, "_self");
    }
  }

  onPaymentSuccess = async () => {
    if (this.state.PlanInfo && this.state.sessionId) {
      const payload = {
        "account_id": this.account_id,
        "plan_id": this.planid.id,
        "user_subscription_type": this.planid.type,
        "amount": this.planid.type === "monthly" ? 
          this.state.PlanInfo.amount : 
          this.state.PlanInfo.amount_yearly,
        "session_id": this.state.sessionId
      }
      
      this.stripePaymentSuccessApiCallId = await apiCall({
        contentType: configJSON.subscriptionApiContentType,
        method: configJSON.postAPiMethod,
        endPoint: configJSON.stripePaymentSuccessEndPoint,
        payload: JSON.stringify(payload),
      });
    }
  }

  handlePaymentSuccessResponse = (responseJson: {
    success: boolean;
    errors: [{ token: string }]
  }) => {
    if (responseJson.errors?.[0]?.token) {
      this.logoutUser();
      this.saveTokenAlert(responseJson.errors[0].token);
      return;
    }

    if (responseJson.success) {
      this.handleDelete();
    }
  }

  handlePaymentFailureResponse = (responseJson: {
    errors: [{ token: string }]
  }) => {
    if (responseJson.errors?.[0]?.token) {
      this.logoutUser();
      this.saveTokenAlert(responseJson.errors[0].token);
    }
  }

  onPaymentFailure = async () => {
    if (this.state.PlanInfo && this.state.sessionId) {
      const payload = {
        "account_id": this.account_id,
        "plan_id": this.planid.id,
        "user_subscription_type": this.planid.type,
        "amount": this.planid.type === "monthly" ? 
          this.state.PlanInfo.amount : 
          this.state.PlanInfo.amount_yearly,
        "session_id": this.state.sessionId
      }
      
      this.stripePaymentFailureApiCallId = await apiCall({
        contentType: configJSON.subscriptionApiContentType,
        method: configJSON.postAPiMethod,
        endPoint: configJSON.stripePaymentFailureEndPoint,
        payload: JSON.stringify(payload),
      });
    }
  }

  navigateToOrgInfoPage = () => {
    this.props.navigation.navigate("ProfilePage", {
      orgTab: "organization-info"
    });
  }
  
  getHighestTierPlanData = async () => {
    const payload = {
      account_id: this.account_id,
    };

    this.getPlanDataApiCallId = await apiCall({
      contentType: configJSON.subscriptionApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getPlanDataEndPoint,
      payload: JSON.stringify(payload),
    });
  }

  handleGetHighestTierPlanData = (response: HighestTierPlanResponse) => {
    if (response.success) {
      this.setState({ 
        highestTierPlanName: response.higher_plan_name 
      });
    }
  }

  // Customizable Area End
}
