Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "POST";
exports.assignApiMethodType = "PATCH";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.dashboardDataUrl = "bx_block_dashboard/users_task_list";
exports.membersDataUrl = "bx_block_dashboard/team_members";
exports.assignTaskUrl = "bx_block_dashboard/assign_task";
exports.getPlanDataEndPoint = "account_block/get_plan_data";
exports.getAccountDataEndPoint = "account_block/index";
exports.handleAccountEndPoint = "account_block/update_status";
exports.getEsgFactorDataEndPoint = "bx_block_dashboard/cards_list";
exports.DeleteUserEndPoint = "account_block/accounts";
exports.getEsgFactorApiMethod = "POST";
exports.getEsgFactorDetailsEndPoint = "bx_block_dashboard/detailed_first_log";
exports.getEsgFactorDetailsApiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.deleteEsgFactorCardEndPoint = "bx_block_dashboard/delete_card";
exports.deleteEsgFactorCardApiMethod = "POST";
exports.archiveEsgFactorCardEndPoint = "bx_block_dashboard/archive_card";
exports.unArchiveEsgFactorCardEndPoint = "bx_block_dashboard/unarchive_card";
exports.graphDataEndpoint = "bx_block_performance_tracker/get_graph_data";
exports.archiveEsgFactorCardApiMethod = "POST";
exports.CategoryColors = {
  Environmental: "#43D270",
  Environment: "#43D270",
  Social: "#7166F1",
  Governance: "#F96C30",
  Flexible: "#F96C30",
  Onsite: "#43D270",
  Remote: "#7166F1"
}
exports.saveEsgFactorSaveEndPoint = "bx_block_dashboard/materiality_answers";
exports.assignCardEndPoint = "bx_block_dashboard/assign_card";
exports.formDataContentType = "application/json";
exports.calculateEndpoint =  "bx_block_dashboard/trycarbon"
exports.getemmisionEndpoint =  "bx_block_dashboard/get_overall_emission"
exports.getorgreportEndpoint =  "bx_block_dashboard/get_organization_report"
exports.getdscreportEndpoint =  "bx_block_dashboard/get_disclosure_report"
exports.getenvreportEndpoint =  "bx_block_dashboard/get_total_environment_report"
exports.calculateEndpoint =  "bx_block_dashboard/trycarbon";
exports.OrganizationInfo = "Organization Info";
exports.ProfileInfo = "Profile Info";
exports.PlanAndBilling = "Plan & Billing";
exports.Users = "Users";
exports.getProfileApiEndPoint = "account_block/get_profile_info";
exports.updateProfileApiEndPoint = "account_block/save_profile";
exports.changePasswordApiEndPoint = "account_block/change_profile_password"
exports.CompanyInfoEndPoint = "account_block/get_company_data";
exports.UpdateCompanyInfoEndPoint = "/account_block/company"
exports.OverallInfoEndPoint = "bx_block_dashboard/check_all_emission";
exports.noDataFound = "No Data Found";
exports.secodaryUserResendMailAPiEndPoint = "account_block/registration/resend_activation_email";
exports.environmentError = "Please update your data on emissions, including water consumption, energy consumption, and waste generation, for atleast one month to generate the graph."
// Customizable Area End