// Customizable Area Start
import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { logoLight } from "../../blocks/DynamicContent/src/assets";
import parse from 'html-react-parser';
import {check} from "../../blocks/customisableusersubscriptions/src/assets"

interface InvoicePDFtype {
  id: number;
  invoice_code: string;
  invoice_date: string;
  plan_name: string;
  quantity: number;
  amount: number;
  total:number
  total_due:number
  sub_total:number
  discount:number
  company_address: string;
  currency_type: string;
  currency_symbol: string;
  contact_place:string
  contact_address:string
  contact_phoneno:string
  contact_email:string
  contact_website:string | null
  plan_features:string[]
}
interface ContactInfo2 {
  contact_phone:string
  contact_email:string
  contact_address:string
  contact_address_link:string
  main_company_name:string
  main_contact_address:string
}

interface AppState {
  invoice: InvoicePDFtype;
  showPDF?: boolean;
  contactInfo: ContactInfo2;
}

const tableLabels = {
  plan: "Plan",
  price: "Price",
  total: "Total",
};

const InvoiceHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 32px",
  background: "#ECFBF1",
});

const InvoicePDFTableContainer = styled(TableContainer)({
  minHeight: "516px",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  paddingLeft: "0px",
  border: "0.5px solid #D7DAE0",
  borderRadius: "12px",
});

const InvoicePDFTable = styled(Table)({
  width: "100%",
  borderCollapse: "separate",
});

const InvoicePDFTableBody = styled(TableBody)({
  color: "inherit",
});

const InvoicePDFTableCellHead = styled(TableCell)({
  minHeight: "48px",
  color: "#000",
  fontFamily: "Lato",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "14px",
  borderBottom: "0.5px solid #D7DAE0",
});

const InvoicePDFTableCell = styled(TableCell)({
  padding: "16px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "14px",
  letterSpacing: "0.2px",
  borderBottom: "0.5px solid #D7DAE0",
});

const InvoicePDFTotalTableCell = styled(TableCell)({
  padding: "16px",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "14px",
  letterSpacing: "0.2px",
  border: "none",
  maxHeight: "30px",
});

const InvoicePDFTableHead = styled(TableHead)({
  backgroundColor: "#FFF",
  borderRadius: "10px",
});

const InvoicePDFTableRowCell = styled(TableRow)({
  borderRadius: "20px !important",
  overflow: "hidden",
  "& :first-child": {
    borderBottomLeftRadius: 10,
  },
  "& :last-child": {
    borderBottomRightRadius: 10,
  },
});

const LogoBlock = styled(Box)({
  width: "145px",
  height: "30px",
  marginLeft: "auto",
});

const StyledBox = styled(Box)({
 '@media(max-width: 600px)': {
    width:"577px"
    }
});

const Logo = styled("img")({
  width: "100%",
});

const InvoicePDF = ({ invoice,contactInfo }: AppState) => {
  const formattedNumber = invoice.amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedTotalAmount = invoice.total.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedSubTotalNumber = invoice.sub_total.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedTotalDueNumber = invoice.total_due.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <StyledBox maxWidth="595px" id="modalContent">
      <InvoiceHeader>
        <div>
          <Typography style={webStyle.header}>INVOICE</Typography>
          <div style={webStyle.textBlock}>
            <p style={{ fontWeight: 600 }}>Billed to</p>
            <p>{invoice.company_address.split(",")[0]}</p>
            <p>{invoice.company_address.split(/,(.+)/)[1]}</p>
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <LogoBlock>
            <Logo src={logoLight} alt="logo" />
          </LogoBlock>
          <div style={webStyle.textBlock}>
            <p style={webStyle.address}>{invoice.contact_place}</p>
            <p style={{ marginTop: "2px" }}>{parse(invoice.contact_address.split(",").slice(0, 3).join(', ').trim().toString())}</p>
            <p>{parse(invoice.contact_address.split(",")[3].trim().toString())}</p>
            <p>{parse(invoice.contact_address.split(",").slice(4).join(', ').trim().toString())}</p>
          </div>
        </div>
      </InvoiceHeader>
      <Box p="28px 32px 12px 32px" style={webStyle.container}>
        <Box style={webStyle.dateContainer as React.CSSProperties}>
          <Box>
            <Typography style={webStyle.labels}>Invoice #</Typography>
            <Typography style={webStyle.value}>
              {invoice.invoice_code}
            </Typography>
          </Box>
          <Box>
            <Typography style={webStyle.labels}>Invoice date</Typography>
            <Typography style={webStyle.value}>
              {invoice.invoice_date}
            </Typography>
          </Box>
        </Box>
        <InvoicePDFTableContainer>
          <InvoicePDFTable aria-label="users table">
            <InvoicePDFTableHead>
              <TableRow>
                <InvoicePDFTableCellHead>
                  {tableLabels.plan}
                </InvoicePDFTableCellHead>
                <InvoicePDFTableCellHead>
                  {tableLabels.price}
                </InvoicePDFTableCellHead>
                <InvoicePDFTableCellHead style={{ textAlign: "right" }}>
                  {tableLabels.total}
                </InvoicePDFTableCellHead>
              </TableRow>
            </InvoicePDFTableHead>
            <InvoicePDFTableBody>
              <InvoicePDFTableRowCell>
                <InvoicePDFTableCell
                  style={{ color: "#000000", fontWeight: 600 }}
                >
                  {invoice.plan_name}
                </InvoicePDFTableCell>
                <InvoicePDFTableCell>{invoice.currency_symbol}&nbsp;{formattedNumber}</InvoicePDFTableCell>
                <InvoicePDFTableCell style={{ textAlign: "right" }}>
                {invoice.currency_symbol}&nbsp;{formattedTotalAmount}
                </InvoicePDFTableCell>
              </InvoicePDFTableRowCell>
            </InvoicePDFTableBody>
            <Typography style={webStyle.featuresIncluded}>Features included:</Typography>
            {invoice.plan_features.map((data,index) =>
            <Box key={index} style={webStyle.planFeatureBox}>
              <img src={check} alt="check" height={18} width={18}/>
               <Typography style={webStyle.planFeatureText}>{data}</Typography>
             </Box>
            )}
          </InvoicePDFTable>
          <InvoicePDFTable
            aria-label="users table"
            style={{ borderTop: "0.5px solid #D7DAE0" }}
          >
            <InvoicePDFTableBody>
              <TableRow>
                <InvoicePDFTotalTableCell
                  style={{ color: "#000000", fontWeight: 600, width: "207px" }}
                >
                  Subtotal
                </InvoicePDFTotalTableCell>
                <InvoicePDFTotalTableCell style={webStyle.celltwo} />
                <InvoicePDFTotalTableCell style={{ textAlign: "right" }}>
                  {invoice.currency_symbol}&nbsp;{formattedSubTotalNumber}
                </InvoicePDFTotalTableCell>
              </TableRow>
              <TableRow>
                <InvoicePDFTotalTableCell
                  style={{ color: "#000000", fontWeight: 600, width: "207px" }}
                >
                  Discount
                </InvoicePDFTotalTableCell>
                <InvoicePDFTotalTableCell style={webStyle.celltwo} />
                <InvoicePDFTotalTableCell style={{ textAlign: "right" }}>
                  {invoice.currency_symbol}&nbsp;{invoice.discount}
                </InvoicePDFTotalTableCell>
              </TableRow>
              <TableRow>
                <InvoicePDFTotalTableCell
                  style={{
                    ...webStyle.borderCellTotal,
                    color: "#000000",
                    fontWeight: 600,
                    width: "150px",
                  }}
                >
                  Total
                </InvoicePDFTotalTableCell>
                <InvoicePDFTotalTableCell style={webStyle.borderCellTotal} />
                <InvoicePDFTotalTableCell
                  style={webStyle.totaldue as React.CSSProperties}
                >
                  {invoice.currency_symbol}&nbsp;{formattedTotalDueNumber}
                </InvoicePDFTotalTableCell>
              </TableRow>
            </InvoicePDFTableBody>
          </InvoicePDFTable>
        </InvoicePDFTableContainer>
      </Box>
      <Box mt="28px" p="0 32px" style={webStyle.footercontainer}>
        <Typography style={webStyle.footertext}>
          {invoice.contact_website}
        </Typography>
        <Typography style={webStyle.footertext}>{invoice.contact_phoneno}</Typography>
        <Typography style={webStyle.footertext}>
          {invoice.contact_email}
        </Typography>
      </Box>
    </StyledBox>
  );
};

export default InvoicePDF;

const webStyle = {
  container: {
    minWidth: "300px",
    maxWidth: "620px",
    display: "flex",
    justifyContent: "start",
  },
  dateContainer: {
    minWidth: "80px",
    maxWidth: "80px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    minHeight: "250px",
    flexDirection: "column",
    gap: "125px",
    marginTop: "10px",
  },
  labels: {
    color: "#000",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "14px",
  },
  value: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "14px",
  },
  borderCellTotal: {
    borderTop: "0.5px solid #D7DAE0",
    background: "#ECFBF1",
  },
  totaldue: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "14px",
    borderTop: "0.5px solid #D7DAE0",
    background: "#ECFBF1",
    letterSpacing: "0.2px",
    textAlign: "right",
    padding: "16px 16px 16px 0",
  },
  celltwo: {
    width: "132px",
  },
  footercontainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "595px",
    height: "70px",
    borderTop: "0.5px solid var(--Gray-50, #EBEDF2)",
    background: "#F5F5F5",
  },
  footertext: {
    color: "var(--Gray-600, #5E6470)",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "14px",
  },
  infotext: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    display: "flex",
    gap: "6px",
    margin: "0px 0 28px 177px",
    alignItems: "center",
  },
  header: {
    fontSize: "28px",
    lineHeight: "32px",
    fontWeight: 600,
    fontFamily: "Lato",
  },
  textBlock: {
    fontSize: "10px",
    lineHeight: "14px",
    marginTop: "24px",
  },
  address: {
    color: "#329E54",
    fontSize: "12px",
    fontWeight: 700,
  },
  featuresIncluded:{
    fontSize:'12px',
    fontWeight:600,
    padding:'15px',
    color:'#000',
    fontFamily: "Lato",
    fontStyle: "normal",
    lineHeight: "14px",
    letterSpacing: "0.2px",
  },
  planFeatureBox:{
    display:'flex',
    alignItems: 'center',
    gap:'5px',
    padding:'5px 15px'
  },
  planFeatureText:{
    fontSize:'10px',
    fontFamily: "Lato",
    fontStyle: "normal"
  }
};
// Customizable Area End