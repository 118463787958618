import React from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Cancel from '@material-ui/icons/Cancel';


interface SnackbarProps {
    onClose: any;
    status: any;
    AlertLabel: string;
    open: boolean;
    dataTestId:string;
    alert?: boolean;
}


const CustomSnackbar = (props: SnackbarProps) => {

    const { onClose, status, AlertLabel, open, alert } = props;
    const classes = useStyles();

    const renderIcon = () => {
        if (status === "success") {
            return (
                <CheckCircleOutlineIcon
                    fontSize="inherit"
                    style={{ color: "rgb(30, 70, 32)" }}
                />
            )
        } else {
            return (
                <Cancel
                    fontSize="inherit"
                    style={{ color: "rgb(30, 70, 32)" }}
                />
            )
        }
    }

    return (
        <Snackbar
            style={{marginTop: "45px"}}
            data-test-id={'snackbar-alert'}
            open={open}
            onClose={onClose}
            autoHideDuration={1000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Alert 
                severity={status}
                className={alert ? classes.containerAlert : classes.container}
                icon={renderIcon()}
            >
                {AlertLabel}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: "#43D270",
    },
    containerAlert: {
        backgroundColor: "#F44336",
    }
}))