Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "multiplelandingpage2";
exports.labelBodyText = "multiplelandingpage2 Body";
exports.Reports = "Reports";
exports.ReportingFramework = "Reporting Framework";
exports.SelectedUNSGs = "Selected UN SDGs";
exports.Marketplace = "Marketplace";
exports.IndustryBenchmarking = "Industry Benchmarking";
exports.MaterialityMatrix = "Materiality Matrix";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End