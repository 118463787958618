import React from "react";
import { Container } from "@material-ui/core";
import DashboardController, { Props } from "../DashboardController";
import PlanAndBillingCard from "../common/PlanAndBillingCard.web";
import Layout from "../Layout.web";

export default class AccessRestrictedPage extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { planData } = this.state;
    
    return (
      <Layout data-test-id="access-restricted-layout">
        <Container maxWidth="lg">
          <div style={{ marginTop: "10%" }}>
            <PlanAndBillingCard
              {...this.props}
              expiryDate={planData.expired_date}
              plan={planData.plan_name}
              higherPlan={planData.higher_plan_name}
              onTodoListClick={this.navigateToGetStartedPage}
            />
          </div>
        </Container>
      </Layout>
    );
  }
}