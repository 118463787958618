// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getMonthYear } from "./MonthYearSelect.web";
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../config");
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export interface Props {
  onCardFrequencyCodeChange: (event: React.ChangeEvent<{value: unknown}>) => void;
}

interface S {
  cardFrequency: string;
  cardFrequencyCode: string;
  dynamicOptionsForCardFrequencyCode: string[];
}

interface SS {
  id: any;
}

function getQuartersFrom2023() {
  const currentYear = new Date().getFullYear(); // Get the current year
  const startYear = 2023;
  let quarters = [];

  // Loop through years starting from 2023 to the current year
  for (let year = startYear; year <= currentYear; year++) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      quarters.push(`Q${quarter}_${year}`);
    }
  }

  return quarters;
}

function getHalfYearliesFrom2023() {
  const currentYear = new Date().getFullYear();
  const startYear = 2023;
  let halfYearlies = [];

  // Loop through years starting from 2023 to the current year
  for (let year = startYear; year <= currentYear; year++) {
    for (let half = 1; half <= 2; half++) {
      halfYearlies.push(`H${half}_${year}`);
    }
  }

  return halfYearlies;
}

function getYearsFrom2023() {
  const currentYear = new Date().getFullYear();
  const startYear = 2023;
  let years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(`${year}`);
  }

  return years;
}

function getCurrentMonth() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = MONTHS[currentDate.getMonth() - 1];
  const currentMonthYear = `${currentMonth}-${currentYear}`;
  const monthYearList = getMonthYear();
  return monthYearList.find(month => month === currentMonthYear);
}

export function getCurrentQuarter() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  // Get the current month (0-based, so +1 to make it 1-based)
  const currentMonth = currentDate.getMonth() + 1; 

  // Calculate the current quarter based on the current month
  let currentQuarter;
  if (currentMonth <= 3) {
    currentQuarter = "Q1";
  } else if (currentMonth <= 6) {
    currentQuarter = "Q2";
  } else if (currentMonth <= 9) {
    currentQuarter = "Q3";
  } else {
    currentQuarter = "Q4";
  }

  // Combine the current quarter and year to form the string
  const currentQuarterString = `${currentQuarter}_${currentYear}`;
  const quarters = getQuartersFrom2023();
  return quarters.find(quarter => quarter === currentQuarterString);
}

export function getCurrentHalfYearly() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  // Determine the current half-year based on the current month
  let currentHalf;
  if (currentMonth <= 6) {
      currentHalf = "H1";
  } else {
      currentHalf = "H2";
  }

  // Combine the current half and year to form the string
  const currentHalfString = `${currentHalf}_${currentYear}`;
  const halfYearlies = getHalfYearliesFrom2023();
  return halfYearlies.find(halfYearly => halfYearly === currentHalfString);
}

export default class CfnzdpuassuranceandverificationController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      cardFrequency: "Yearly",
      cardFrequencyCode: "2023",
      dynamicOptionsForCardFrequencyCode: getYearsFrom2023(),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const locallySavedCardFrequency = await getStorageData("cardFrequency");
    const locallySavedCardFrequencyCode = await getStorageData("cardFrequencyCode");
    if (locallySavedCardFrequency !== null && locallySavedCardFrequencyCode !== null) {
      this.setState({ 
        cardFrequency: locallySavedCardFrequency,
        cardFrequencyCode: locallySavedCardFrequencyCode, 
      })
    } else {
      setStorageData("cardFrequency", "Yearly");
      setStorageData("cardFrequencyCode", "2023");
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.cardFrequency !== prevState.cardFrequency) {
      switch (this.state.cardFrequency) {
        case "All":
          this.manuallyTriggerCardFrequencyCodeChange("All");
          this.setState({
            cardFrequencyCode: "All",
            dynamicOptionsForCardFrequencyCode: ["All"]
          });
          break;
        case "Monthly":
          const months = getMonthYear();
          const month = this.state.cardFrequencyCode.includes("-") 
            ? this.state.cardFrequencyCode 
            : getCurrentMonth() as string;
          this.manuallyTriggerCardFrequencyCodeChange(month);
          this.setState({ 
            cardFrequencyCode: month,
            dynamicOptionsForCardFrequencyCode: months,
          });
          break;
        case "Quarterly":
          const quarters = getQuartersFrom2023();
          const quarter = this.state.cardFrequencyCode.includes("Q") 
            ? this.state.cardFrequencyCode 
            : getCurrentQuarter() as string;
          this.manuallyTriggerCardFrequencyCodeChange(quarter);
          this.setState({ 
            cardFrequencyCode: quarter,
            dynamicOptionsForCardFrequencyCode: quarters,
          });
          break;
        case "Half-yearly":
          const halfYearlies = getHalfYearliesFrom2023();
          const halfYearly = this.state.cardFrequencyCode.includes("H") 
            ? this.state.cardFrequencyCode 
            : getCurrentHalfYearly() as string;
          this.manuallyTriggerCardFrequencyCodeChange(halfYearly);
          this.setState({ 
            cardFrequencyCode: halfYearly,
            dynamicOptionsForCardFrequencyCode: halfYearlies,
          });
          break;
        case "Yearly":
          const years = getYearsFrom2023();
          const year = !isNaN(Number(this.state.cardFrequencyCode)) 
            ? this.state.cardFrequencyCode : 
            years[years.length - 1];
          this.manuallyTriggerCardFrequencyCodeChange(year);
          this.setState({ 
            cardFrequencyCode: year,
            dynamicOptionsForCardFrequencyCode: years,
          });
          break;
      
        default:
          break;
      }
    }
  }

  handleCardFrequencyChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setStorageData("cardFrequency", event.target.value as string);
    this.setState({
      cardFrequency: event.target.value as string
    });
  }

  handleCardFrequencyCodeChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setStorageData("cardFrequencyCode", event.target.value as string);
    this.props.onCardFrequencyCodeChange(event);
    this.setState({ 
      cardFrequencyCode: event.target.value as string
    });
  }

  manuallyTriggerCardFrequencyCodeChange = (value: string) => {
    const fakeEvent = {
      target: {
        value: "",
      }
    } as React.ChangeEvent<{value: unknown}>

    fakeEvent.target.value = value;
    this.props.onCardFrequencyCodeChange(fakeEvent);
    setStorageData("cardFrequencyCode", value);
  }

}
// Customizable Area End