import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";
import WidgetHeadingText from "../WidgetHeadingText";

const useStyles = makeStyles(() =>
  createStyles({
    blockValue: {
      padding: "20px 50px",
      "@media (max-width: 768px)": {
        padding: "20px",
      },
    },
    blockHeading: {
      textAlign: "center",
    },
    heading: {
      fontWeight: 700,
      textTransform: "capitalize",
    },
    heading4: {
      fontSize: "26px",
      fontWeight: 500,
      lineHeight: "40px",
      textTransform: "capitalize",
    },
    body: {
      marginTop: "16px",
      color: "#686868",
    },
    listValues: {
      marginTop: "40px",
      "@media (max-width: 768px)": {
        marginTop: "20px",
      },
    },
    icon: {
      width: "60px",
      height: "60px",
    },
    title: {
      fontSize: "24px",
      fontWeight: 700,
      color: "#191919",
      lineHeight: "32px",
      textTransform: "capitalize",
      marginTop: "24px",
      "@media (max-width: 768px)": {
        fontSize: "18px",
        lineHeight: "26px",
        marginTop: "16px",
      },
    },
    description: {
      marginTop: "12px",
      color: "#686868",
    },
    valueItem: {
      backgroundColor: "#fff",
      borderRadius: "20px",
      height: "100%",
    },
  })
);

export default function Value(props: any) {
  const { header, description, journey_card_widget_items } = props;
  const styles = useStyles();
  return (
    <div className={styles.blockValue}>
      <Container style={{ padding: 0 }}>
        <div className={styles.blockHeading}>
          <WidgetHeadingText>{parse(header)}</WidgetHeadingText>
          <p className={styles.body}>{parse(description)}</p>
        </div>
        <Grid container className={styles.listValues} spacing={4}>
          {journey_card_widget_items.data.map((item: any) => {
            const {
              card_image,
              card_title,
              card_description,
            } = item.attributes;
            return (
              <Grid item md={6} lg={3} key={item.id}>
                <div className={styles.valueItem}>
                  {card_image ?
                    <img
                    src={card_image}
                    alt={card_title}
                    className={styles.icon}
                    /> : null
                  }
                  <h5 className={styles.title}>{card_title}</h5>
                  <p className={styles.description}>
                    {parse(card_description)}
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
