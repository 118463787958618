import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  styled,
  FormControl,
  TextField,
  Grid,
  Typography as TypographyMUI,
  Button as ButtonMUI,
  withStyles,
  Tooltip,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, FieldArray, getIn } from "formik";
import { v4 as uuidv4 } from 'uuid';
import * as yup from "yup";
import Layout from "../../dashboard/src/Layout.web";
import SelectFieldStyled from "../../dashboard/src/common/SelectFieldStyled.web";
import Activity from "./components/Activity";
import SuccessDialog from "./components/SuccessDialog";
import FailureDialog from "./components/FailureDialog";
import { infoIcon } from "./assets";

const Container = styled("div")({
  width: "100%",
  padding: "32px 44px 20px 44px"
});

const PageContent = styled("div")({
  marginBottom: "23px"
});

const HeadingRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "17px"
});

const MainHeading = styled(TypographyMUI)({
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "27.5px"
});

const CustomButton = styled(ButtonMUI)({
  minHeight: "40px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#43D270",
  color: "#34373A",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  '& span': {
    fontFamily: "Inter",
    fontWeight: 700,
  }
});

const DisabledButton = styled(CustomButton)({
  pointerEvents: "none",
  background: "#B1B3C3",
  "&:hover": {
    background: "#B1B3C3"
  }
});

const Text = styled(TypographyMUI)({
  color: "#7D7D7F",
  textAlign: "left",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "19.2px"
});

const FieldContainer = styled(FormControl)({
  width: "100%",
  borderBottom: "1px solid #EBEBEB",
});

const TextInput = styled(TextField)({
  width: '100%',
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  '& *': {
    borderColor: "rgba(0, 0, 0, 0.2) !important",
  },
});

const TextResult = styled(TextInput)({
  width: "404px",
  "@media all and (max-width: 600px)": {
    width: "100%"
  }
});

const LabelStyled = styled(TypographyMUI)({
  fontSize: "15px",
  fontFamily: "Lato",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "8px"
});

const AddMoreBtnContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  margin: "20px 0 30px 0",
});

const AddMoreButton = styled(ButtonMUI)({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "19.2px",
  textAlign: "right",
  color: "#43D270",
  textTransform: "capitalize",
});

const DeleteButton = styled(AddMoreButton)({
  position: "absolute", 
  bottom: "-25px", 
  right: "7px"
});

const TooltipContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const InfoIcon = styled("img")({
  height: "16px",
  width: "16px",
  marginLeft: "10px",
  marginBottom: "8px",
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#7D7D7F",
    width: "274.427px",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
    display: "inline-flex",
    height: "71px",
    padding: "7px 0px 7px 16.048px",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    background: "#FFF",
    boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11)"
  },
}))(Tooltip);

const CircularProgressContainer = styled("div")({
  display: "flex", 
  justifyContent: "center",
  alignItems: "center", 
  height: "calc(100vh - 145px)", 
	color: "grey", 
});

const CustomPaper = styled(Paper)({
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  background: "#FFF",
  maxHeight: '250px',
});

const ErrorAlert = styled("div")({
  color: "#ff0000",
  lineHeight: 1,
  marginTop: "10px",
  fontFamily: "Lato",
  fontSize: "15px"
});

const emptyRow = {
  id: uuidv4(),
  inputs: {
    region: {type: "dropdown", name: "region", value: "Singapore"},
    activity_id: {type: "dropdown", name: "activity_id", value: ""},
    energy: {type: "text", name: "energy", value: ""},
    energy_unit: {type: "dropdown", name: "energy_unit", value: ""},
  }
}

const validationSchema = yup.object().shape({
  inputRows: yup.array().of(
    yup.object().shape({
      inputs: yup.object().shape({
        region: yup.object({ value: yup.string().required("Please select one option") }),
        activity_id: yup.object({ value: yup.string().required("Please select one option") }),
        energy: yup.object({ value: yup.string().required("Please provide energy") }),
        energy_unit: yup.object({ value: yup.string().required("Please select one option") }),
      })
    })
  )
});

const tooltipDescription = "Total CO2 emissions generated by the activity, measured in kilograms";

// Customizable Area End

import CfclimatiqbasicestimateController, {
  Props,
  configJSON,
} from "./CfclimatiqbasicestimateController";

export default class Cfclimatiqbasicestimate extends CfclimatiqbasicestimateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout>
        {
          this.state.isLoadingEnergyConsumption ? (
            <CircularProgressContainer>
              <CircularProgress data-test-id="circular-progress" color="inherit" />
            </CircularProgressContainer>
          ) : (
            <Container style={{ pointerEvents: this.state.hasSavedMetrics ? "none" : "auto" }}>
              <Formik 
                data-test-id="energy-consumption-form"
                enableReinitialize
                initialValues={{
                  inputRows: this.state.inputRows
                }}
                validationSchema={validationSchema}
                onSubmit={this.handleFormSubmit}
              >
                {formikProps => {
                  const enableSaveButton = formikProps.isValid && !this.state.hasSavedMetrics && this.state.co2Result;
                  return (
                    <>
                      <PageContent>
                        <HeadingRow>
                          <MainHeading>{this.state.pageTitle}</MainHeading>
                          {!enableSaveButton ?
                            <DisabledButton data-test-id="save_button" aria-disabled="true" onClick={this.saveMetrics}>Save</DisabledButton>
                            :
                            <CustomButton data-test-id="save_button" onClick={this.saveMetrics}>Save</CustomButton>
                          }
                        </HeadingRow>
                        <Text>{this.state.pageDescription}</Text>
                      </PageContent>
                      <Form>
                        <FieldArray 
                          name="inputRows"
                          render={arrayHelpers => {
                            return (
                              <FieldContainer data-test-id="field-container">
                                {formikProps.values.inputRows.length > 0 && formikProps.values.inputRows.map((inputRow, rowIndex) => {
                                  return (
                                    <Grid 
                                      container 
                                      spacing={2}
                                      alignItems="baseline"
                                      key={inputRow.id}
                                      style={{ position: "relative", marginBottom: "20px" }}
                                    >
                                      {
                                        Object.values(inputRow.inputs).map((input, inputIndex) => {
                                          if (input.type === "dropdown" && input.name === "region") {
                                            const value = {
                                              id: inputIndex,
                                              value: input.value,
                                              label: input.value,
                                            }
                                            const label = this.generateLabelFromParamName(input.name);
                                            const options = this.generateOptionsFromParamName(input.name);
                                            const name = `inputRows[${rowIndex}].inputs[${input.name}].value`;
                                            const errorMessage = getIn(formikProps.touched, name) && getIn(formikProps.errors, name)

                                            return (
                                              <Grid key={input.name} item xs={12} md={3}>
                                                <LabelStyled>{label}</LabelStyled>
                                                <Autocomplete
                                                  disablePortal
                                                  // @ts-ignore
                                                  name={name}
                                                  options={options}
                                                  data-test-id="region-autocomplete"
                                                  value={value}
                                                  onChange={(event, region) => {
                                                    formikProps.setFieldValue(
                                                      name,
                                                      region !== null ? region.value : ""
                                                    );
                                                  }}
                                                  PaperComponent={CustomPaper}
                                                  closeIcon={false}
                                                  popupIcon={<div style={{ margin: '0px 12px' }}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                          <path d="M15 7.5L10 12.5L5 7.5" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                      </svg>
                                                  </div>}
                                                  PopperComponent={({ children, ...other }) => (
                                                      <div {...other} style={webStyle.modelpopper}>
                                                          {children}
                                                      </div>
                                                  )}
                                                  getOptionLabel={option => option.label}
                                                  renderOption={option => option.label}
                                                  renderInput={params => (
                                                    <TextField
                                                      {...params}
                                                      placeholder="Select"
                                                      inputProps={{
                                                        ...params.inputProps,
                                                        style: { 
                                                          fontSize: "15px",
                                                          padding: "2px 0" 
                                                        }
                                                      }}
                                                      InputProps={{
                                                          ...params.InputProps,
                                                          disableUnderline: true,
                                                          style: webStyle.autoComplete,
                                                      }}
                                                      FormHelperTextProps={{
                                                        style: {
                                                          color: "#ff0000",
                                                          lineHeight: 1,
                                                          margin: "10px 0 0 0",
                                                          fontFamily: "Lato",
                                                          fontSize: "15px"
                                                        }
                                                      }}
                                                    />
                                                  )}
                                                />
                                                <ErrorAlert>{errorMessage}</ErrorAlert>
                                              </Grid>
                                            );
                                          } else if (input.type === "dropdown") {
                                            const label = this.generateLabelFromParamName(input.name);
                                            const options = this.generateOptionsFromParamName(input.name);
                                            const name = `inputRows[${rowIndex}].inputs[${input.name}].value`;
                                            return (
                                              <Grid key={input.name} item xs={12} md={3}>
                                                <SelectFieldStyled
                                                  noMarginBottom
                                                  noFixedWidth
                                                  name={name}
                                                  value={input.value}
                                                  onChange={formikProps.handleChange}
                                                  options={options}
                                                  label={label}
                                                  placeholder="Select"
                                                  defaultSelectLabel="Select"
                                                  error={
                                                    getIn(formikProps.touched, name) &&
                                                    getIn(formikProps.errors, name)
                                                  }
                                                  errorMessage={
                                                    getIn(formikProps.touched, name) &&
                                                    getIn(formikProps.errors, name)
                                                  }
                                                />
                                              </Grid>
                                            );
                                          } else if (input.type === "text") {
                                            const name = `inputRows[${rowIndex}].inputs[${input.name}].value`;
                                            return (
                                              <Grid key={input.name} item xs={12} md={3}>
                                                <LabelStyled>Energy</LabelStyled>
                                                <TextInput 
                                                  id="outlined-basic" 
                                                  variant="outlined"
                                                  name={name}
                                                  value={input.value}
                                                  onChange={formikProps.handleChange}
                                                  placeholder="Write energy"
                                                  error={
                                                    getIn(formikProps.touched, name) &&
                                                    getIn(formikProps.errors, name)
                                                  }
                                                  helperText={
                                                    getIn(formikProps.touched, name) &&
                                                    getIn(formikProps.errors, name)
                                                  }
                                                  inputProps={{
                                                    style: { fontSize: "15px" }
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      height: "50px",
                                                      borderRadius: "8px",
                                                    }
                                                  }}
                                                  FormHelperTextProps={{
                                                    style: {
                                                      color: "#ff0000",
                                                      lineHeight: 1,
                                                      margin: "10px 0 0 0",
                                                      fontFamily: "Lato",
                                                      fontSize: "15px"
                                                    }
                                                  }}
                                                />
                                              </Grid>
                                            );
                                          }
                                        })
                                      }
                                      <DeleteButton
                                        data-test-id="delete-row-btn"
                                        onClick={() => arrayHelpers.remove(rowIndex)}
                                        style={{ display: arrayHelpers.form.values.inputRows.length === 1 ? "none" : "block" }}
                                      >
                                        Delete
                                      </DeleteButton>
                                    </Grid>
                                  )
                                })}
                                <AddMoreBtnContainer>
                                  <AddMoreButton 
                                    data-test-id="add-more-btn"
                                    onClick={() => arrayHelpers.push(emptyRow)}
                                  >
                                    + Add more
                                  </AddMoreButton>
                                </AddMoreBtnContainer>
                              </FieldContainer>
                            );
                          }}
                        />
                        <CustomButton 
                          style={{width:"207px" ,height:"40px", margin: "40px 0"}}
                          type="submit"
                        >
                          Calculate
                        </CustomButton>
                      </Form>
                    </>
                  )
                }}
              </Formik>
              <TooltipContainer>
                <LabelStyled>Resulting CO2 in kgs</LabelStyled>
                <LightTooltip title={tooltipDescription}>
                  <InfoIcon src={infoIcon} alt="info icon" />
                </LightTooltip>
              </TooltipContainer>
              <TextResult 
                data-test-id="co2Result"
                id="outlined-basic" 
                variant="outlined"
                value={this.state.co2Result}
                inputProps={{
                  style: { fontSize: "15px" }
                }}
                InputProps={{
                  style: {
                    height: "50px",
                    borderRadius: "8px",
                  }
                }}
              />
              <Activity 
                comment={this.state.comment}
                onComment={this.handleCommentChange}
                activities={this.state.userActivites}
              />
              <FailureDialog 
                open={this.state.openFailureDialog}
                onClose={this.handleCloseFailureDialog}
                message={this.state.failureMessage}
              />
              <SuccessDialog 
                open={this.state.openSuccessDialog}
                onClose={this.handleCloseSuccessDialog}
                onContactUsClick={this.navigateToContactUs}
              />
            </Container>
          )
        }
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  autoComplete: {
    height: "50px",
    maxWidth: '404px',
    borderRadius: '8px',
    border: "1px solid #D9D9D9",
    padding: '12px',
    boxShadow: 'none'
  },
  modelpopper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    maxWidth: '404px',
    boxShadow: 'none !important',
    padding: '0px !important',
    zIndex: 1,
    position: 'absolute'
  } as React.CSSProperties,
}
// Customizable Area End
