import React from "react";

import {
  // Customizable Area Start
  Typography
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Layout from "../Layout.web";
import { Link } from "react-router-dom";
import { 
    userImg, 
    briefcaseImg, 
    activeBriefcaseImg, 
    activeUserImg,
    planIcon,
    planIconActive,
    usersIcon,
    usersIconActive,
} from "../assets";
import ProfileInfo from "./ProfileInfoPage.web"
import OrganizationInfo from "./OrganizationInfo.web";
import PlanAndBillingCard from "../common/PlanAndBillingCard.web";
import UsersTable from "../common/UsersTable.web";

const HubTabsSection = styled("div")({
    flexWrap: "wrap",
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "32px",
    alignItems: "center",
    '@media(max-width: 450px)': {
      alignItems: 'end'
    },
});

const InnerContainer = styled("div")({
    height: "auto",
    padding: "32px 37px 44px 37px",
    width: "100%"
});

const HubTabItemSelected = styled("div")({
    justifyContent: "center",
    display: "flex",
    padding: "8px 12px",
    gap: "8px",
    alignItems: "center",
    borderRadius: "8px",
    flexShrink: 0,
    color: "grey",
    backgroundColor: "#ECFBF1",
    cursor: "pointer",
    fontSize: "15px",
});

const HubTabItem = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 12px",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    backgroundColor: "#FFF",
    color: "grey",
    fontSize: "15px",
    height: "40px",
    cursor: "pointer",
});


const HubTabText = styled(Typography)({
    color: "#7D7D7F",
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontStyle: "normal",
    cursor: "pointer",
    letterSpacing: "0.2px",
});

const HubTabTextSelected = styled(HubTabText)({
    fontWeight: 700,
    color: "#329E54",
});

const HubIcon = styled("span")({
    height: "20px",
    width: "20px",
});

const HubTabContent = styled('div')({
    width: "100%",
})

const HubTabLink = styled(Link)({
    display: "flex",
    width: "100%",
    textDecoration: "none",
    justifyContent: "center",
    gap: "8px",
    alignItems: "center",
    flexShrink: 0,
})

const HubTabs = styled('div')({
    display: 'flex',
    gap: '8px',
    maxWidth: '404px',
    '@media(max-width: 650px)': {
        alignItems: 'start',
        flexDirection: 'column'
    },
})


const OrgBaseUrl = "/settings"
// Customizable Area End

import DashboardController, {
  Props,
  configJSON,
} from "../DashboardController";

export default class ProfilePage extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const accountPlanData = this.state.planData;
    const activeTab = this.props.navigation ? this.props.navigation.getParam('orgTab') : 'Profile';

        const InnerTabsData = [
            {
                id: 1,
                text: configJSON.OrganizationInfo,
                icon: briefcaseImg,
                activeIcon: activeBriefcaseImg,
                component: <OrganizationInfo navigation={this.props.navigation} id={""}  />,
                isActive: activeTab === "organization-info",
                link: "/organization-info"
            },
            {
                id: 2,
                text: configJSON.ProfileInfo,
                icon: userImg,
                activeIcon: activeUserImg,
                component: <ProfileInfo navigation={ this.props.navigation } id={""} />,
                isActive: activeTab === "profile",
                link: "/profile",
            },
            {
                id: 4,
                text: configJSON.Users,
                icon: usersIcon,
                activeIcon: usersIconActive,
                component: <UsersTable {...this.props} />,
				isActive: activeTab === "users",
				link: "/users"
            },
        ];
    return (
        <>
        <Layout>
            <InnerContainer >
                <HubTabsSection >
                    <HubTabs >
                     {InnerTabsData.map(( tab ) => {

                            return  tab.isActive ? (
                            <HubTabItemSelected
                                    data-test-id="tab"
                                    key = {`selected_tab_${tab.id }`}
                                >
                                 <HubIcon><img src = {tab.activeIcon} alt="" /></HubIcon>

                                <HubTabTextSelected data-test-id="tab-name">{tab.text}</HubTabTextSelected>

                            </HubTabItemSelected >
                            ) : (
                                <HubTabItem
                                    data-test-id="tab"
                                    key = {`noneselected_tab_${tab.id}`}
                                >
                                    <HubTabLink to = {OrgBaseUrl + tab.link}>

                                       <HubIcon> <img src={tab.icon} alt="" /> </HubIcon>

                                       <HubTabText data-test-id="tab-name">{tab.text}</HubTabText>
                                    </HubTabLink>
                                </HubTabItem>
                            );
                        })}
                    </HubTabs>

                </HubTabsSection>

                {InnerTabsData.map(( content ) => {

                    return <HubTabContent  key={`content${ content.id }`}> { content.isActive ? content.component : "" }</HubTabContent>
               
               })}
           </InnerContainer>
       </Layout>
   </>
    );
    // Customizable Area End
  }

}

// Customizable Area Start
// Customizable Area End
