import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import parse from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WidgetHeadingText from "./WidgetHeadingText";
import Slash from "./Slash";
const dontImage = require("../../src/testimonial.svg");
const dontInactiveImage = require("../../src/inactive-testimonial.svg");

const useStyles = makeStyles({
  mainBlock: {
    padding: "80px 50px 120px 50px",
    background: "#f5f6fb",
    textAlign: "center",
    "@media (max-width: 768px)": {
      padding: "40px 20px 80px",
    },
  },
  description: {
    marginTop: "8px",
    color: "#3A3A3A"
  },
  testimonialBlock: {
    marginTop: "40px",
    height: "300px",
  },
  card: {
    position: "relative",
    cursor: "pointer",
    transition: "all linear 0.3s",
    border: "1px solid #e4e4e4",
    borderRadius: "16px",
    padding: "40px 32px",
    margin: "0 16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#fff",
    "@media (max-width: 768px)": {
      padding: "20px 16px",
      flexDirection: "column",
       marginBottom:'16px'
    },
    "&:hover": {
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      "& $doubleSlash": {
        opacity: 1,
        right: "32px"
      }
    }
  },
  avatar: {
    width: "120px",
    height: "120px",
    objectFit: "cover",
    aspectRatio: "1/1",
    borderRadius: "50%",
    "@media (max-width: 768px)": {
      width: "60px",
      height: "60px",
    },
  },
  body: {
    "&, & > *": {
      marginTop: "12px",
      fontSize: "20px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      lineHeight: "28px",
      color: "#686868",
      "@media (max-width: 768px)": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    }
  },
  info: {
    marginTop: "12px",
  },
  name: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      fontSize: "14px",
      lineHeight: "22px",
    }
  },
  role: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#686868",
  },
  doubleSlash: {
    position: "absolute",
    right: "10px",
    bottom: "40px",
    opacity: 0,
    transition: "all linear 0.3s"
  },
  slashIcon: {
    fontSize: "31px",
    "&:before": {
      color: "#5b45f3"
    }
  },
  cardInner: {
    textAlign: "left",
    "@media (max-width: 768px)": {
      textAlign: "center",
    }
  },
  customDot: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    cursor:'pointer',
    backgroundSize: "cover",
  },
  customDotInactive: {
    backgroundImage: `url(${dontInactiveImage})`
  },
  customDotActive: {
    backgroundImage: `url(${dontImage})`, 
  },
  slide:{
    '@media (max-width:600px)': {
      marginBottom:'-20px !important',
      paddingLeft:'0px'
    },
  },
  slickList: {
    '@media (min-width:600px)': {
      margin: "0 -10px",
      height: "330px !important",
    },
  }
});

const Testimonials = (props: any) => {
  const {main_header, header, description, testimonial_widget_items} = props;
  const styles = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    className: `slider variable-width ${styles.slickList}`,
    customPaging: (i:any) => (
      <div className={`${styles.customDot} slick-dot-${i}`} />
    ),
    appendDots: (dots: React.ReactNode) => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px"}} className={styles.slide} >{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className={styles.mainBlock}>
      <WidgetHeadingText text={main_header} />
      <p className={styles.description}>{parse(header)}</p>
      {description && <p className={styles.description}>{parse(description)}</p>}
      <div className={styles.testimonialBlock}>
        <Slider {...settings}>
          {testimonial_widget_items.data.map((item: any) => {
            const {
              testimonial_name,
              testimonial_description,
              testimonial_designation,
              testimonial_company,
              testimonial_star_rating,
              testimonial_image,
            } = item.attributes;
            return (
              <div key={item.id} style={{ width: 660 }}>
                <div className={styles.card}>
                  <img
                    src={testimonial_image}
                    alt={testimonial_name}
                    className={styles.avatar}
                  />
                  <div className={styles.cardInner}>
                    <Rating
                      name="read-only"
                      readOnly
                      value={testimonial_star_rating}
                    />
                    <div className={styles.body}>
                      {parse(testimonial_description)}
                    </div>
                    <div className={styles.info}>
                      <h4 className={styles.name}>{testimonial_name}</h4>
                      <div className={styles.role}>
                        {`${testimonial_designation} ${testimonial_company}`}
                      </div>
                      <div className={`${styles.doubleSlash} double-slash`}>
                        <Slash/>
                        <Slash/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
