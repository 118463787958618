// Customizable Area Start
import React from "react";
import MonthYearSelect from "./MonthYearSelect.web";

const CARD_FREQUENCY_OPTIONS = ["All", "Monthly", "Quarterly", "Half-yearly", "Yearly"];

import EsgCardFilterController, {
  Props,
} from "./EsgCardFilterController.web";

export default class EsgCardFilter extends EsgCardFilterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div style={{ display: "flex", gap: "15px" }}>
        <MonthYearSelect
          data-test-id="select-card-frequency"
          fullwidth="60px"
          value={this.state.cardFrequency}
          dynamicOptions={CARD_FREQUENCY_OPTIONS}
          onChange={this.handleCardFrequencyChange}
        />
        <MonthYearSelect
          data-test-id="select-card-frequency-code"
          onChange={this.handleCardFrequencyCodeChange}
          value={this.state.cardFrequencyCode}
          dynamicOptions={this.state.dynamicOptionsForCardFrequencyCode}
        />
      </div>
    );
  }
}
// Customizable Area End
