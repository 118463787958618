import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { v4 as uuidv4 } from 'uuid';
import { apiCall } from "../../cfnzdpuassuranceandverification/src/CfnzdpuassuranceandverificationController";
import { getStorageData } from "../../../framework/src/Utilities";

interface CardDetails {
  taskId: number;
  materialFactorId: number | null;
  categoryId: number;
  coreFactorId: number;
  cardId: number;
  status: string;
}

interface InputRow {
  id: string,
  inputs: Inputs
}

interface Inputs {
  activity_id: InputDefinition;
  energy: InputDefinition;
  energy_unit: InputDefinition;
  region: InputDefinition;
  [key: string]: InputDefinition;
}

interface InputDefinition {
  name: string;
  type: string;
  value: string;
}

interface InputResponse {
  param_name: string;
  param_type: string;
}

interface Question {
  id: number;
  answer: string;
  question_type: string;
  traditional_input: InputValueResponse[];
  api_datas: {
    api_params_data: InputResponse[];
  },
}

interface Activity {
  image?: string;
  activity_date_time: string;
  activity_by?: null;
  activity: string;
}

interface EnergyConsumptionDetailsResponse {
  success: boolean;
  data: {
    card_title: string;
    card_description: string;
    general_answer_data: unknown;
    questions: Question[];
    activity_logs_data: Activity[];
  }
}

interface InputValueResponse {
  region: string;
  energy: string;
  energy_unit: string;
  activity_id: string;
}

interface RegionResponse {
  id: number;
  name: string;
}

interface RegionsResponse {
  data: RegionResponse[];
}

interface Option {
  id: number;
  value: number | string;
  label: string;
}

interface EnergyUnitsResponse {
  data: string[];
  success: boolean;
}

interface EnergySourceResponse {
  success: boolean;
  data: {
    id: number;
    name: string;
  }[];
}

interface SaveMetricsResponse {
  success: boolean;
  errors?: {
    account: string;
  }
}

const years = [
  { id: 1, value: "2015", label: "2015" },
  { id: 2, value: "2016", label: "2016" },
  { id: 3, value: "2017", label: "2017" },
  { id: 4, value: "2018", label: "2018" },
  { id: 5, value: "2019", label: "2019" },
  { id: 6, value: "2020", label: "2020" },
  { id: 7, value: "2021", label: "2021" },
  { id: 8, value: "2022", label: "2022" },
  { id: 9, value: "2023", label: "2023" },
  { id: 10, value: "2024", label: "2024" },
  { id: 11, value: "2025", label: "2025" },
  { id: 12, value: "2026", label: "2026" },
  { id: 13, value: "2027", label: "2027" },
  { id: 14, value: "2028", label: "2028" },
  { id: 15, value: "2029", label: "2029" },
  { id: 16, value: "2030", label: "2030" },
  { id: 17, value: "2031", label: "2031" },
  { id: 18, value: "2032", label: "2032" },
  { id: 19, value: "2033", label: "2033" },
  { id: 20, value: "2034", label: "2034" },
  { id: 21, value: "2035", label: "2035" }
];

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoadingEnergyConsumption: boolean;
  pageTitle: string;
  pageDescription: string;
  inputRows: InputRow[];
  regions: Option[];
  energyUnits: Option[];
  energySource: Option[];
  cardId: number;
  taskId: number;
  co2Result: string;
  hasSavedMetrics: boolean;
  comment: string;
  questionId: number;
  questionType: string;
  openSuccessDialog: boolean;
  openFailureDialog: boolean;
  failureMessage: string;
  userActivites: Activity[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfclimatiqbasicestimateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEnergyConsumptionDetailsApiCallId: string = "";
  getRegionsApiCallId: string = "";
  getEnergyUnitsApiCallId: string = "";
  getEnergySourceApiCallId: string = "";
  saveCalculateCo2ApiCallId: string = "";
  saveMetricsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoadingEnergyConsumption: false,
      pageTitle: "",
      pageDescription: "",
      inputRows: [],
      regions: [],
      energyUnits: [],
      energySource: [],
      cardId: -1,
      taskId: -1,
      co2Result: "",
      hasSavedMetrics: false,
      comment: "",
      questionId: -1,
      questionType: "",
      openSuccessDialog: false,
      openFailureDialog: false,
      failureMessage: "",
      userActivites: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    // Handle api responses
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      switch (apiRequestCallId) {
        case this.getEnergyConsumptionDetailsApiCallId:
          this.handleEnergyConsumptionDetails(responseJson);
          break;
        case this.getRegionsApiCallId:
          this.handleRegionsResponse(responseJson);
          break;
        case this.getEnergyUnitsApiCallId:
          this.handleEnergyUnitsResponse(responseJson);
          break;
        case this.getEnergySourceApiCallId:
          this.handleEnergySourceReponse(responseJson);
          break;
        case this.saveCalculateCo2ApiCallId:
          this.handleCo2Calculation(responseJson);
          break;
        case this.saveMetricsApiCallId:
          this.handleSaveMetricsResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const cardDetails = await getStorageData("energyConsumptionPayload");
    const cardDetailsJson = JSON.parse(cardDetails);
    this.getEnergyConsumptionDetails(cardDetailsJson);
    this.getRegions();
    this.getEnergyUnits();
    this.getEnergySource();
  }

  // Get energy consumption details
  getEnergyConsumptionDetails = async (cardDetails: CardDetails) => {
    this.setState({ 
      isLoadingEnergyConsumption: true,
      cardId: cardDetails.cardId,
      taskId: cardDetails.taskId,
    });

    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    if (userDetails) {
      const payload = { 
        account_id: userDetails.meta.id,
        card_id: cardDetails.cardId,
        category_id: cardDetails.categoryId,
        core_factor_id: cardDetails.coreFactorId,
        material_factor_id: cardDetails.materialFactorId,
        task_id: cardDetails.taskId
      };
  
      this.getEnergyConsumptionDetailsApiCallId = await apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.postAPiMethod,
        endPoint: configJSON.getEnergyConsumptionDetailsAPiEndPoint,
        payload: JSON.stringify(payload),
      }); 
    }
  }

  handleEnergyConsumptionDetails = (response: EnergyConsumptionDetailsResponse) => {
    
    if (!response.success) {
      return;
    }
    
    let savedInputs: InputValueResponse[] = [];
    // When energy consumption is not saved
    if (response.data.general_answer_data !== null) {
      this.setState({ hasSavedMetrics: true });
      savedInputs = response.data.questions[0].traditional_input ?? [];
    }
    
    this.setState({
      isLoadingEnergyConsumption: false,
      questionId: response.data.questions[0].id,
      questionType: response.data.questions[0].question_type,
      co2Result: response.data.questions[0].answer,
      pageTitle: response.data.card_title,
      pageDescription: response.data.card_description,
      userActivites: response.data.activity_logs_data,
    });

    const inputDefinitions = response.data.questions[0].api_datas.api_params_data;

    if (savedInputs.length === 0) {
      // Create an empty inputRow of inputs to be rendered via Formik 
      const inputRows = [
        {
          id: uuidv4(),
          inputs: {
            region: {type: "dropdown", name: "region", value: "Singapore"},
            activity_id: {type: "dropdown", name: "activity_id", value: ""},
            energy: {type: "text", name: "energy", value: ""},
            energy_unit: {type: "dropdown", name: "energy_unit", value: ""},
          }
        }
      ];
      this.setState({ inputRows });
    } else {
      // Transform reponse to show saved rows via Formik
      let inputRows: InputRow[] = [];
      savedInputs.forEach(savedInput => {
        const inputs = {} as Inputs;
        for (const [property, value] of Object.entries(savedInput)) {
          const inputDefinition = inputDefinitions.find(definition => definition.param_name === property);
          if (inputDefinition) {
            inputs[property] = {
              type: inputDefinition.param_type,
              name: property,
              value,
            }
          }
        }
        inputRows = [
          ...inputRows,
          {
            id: uuidv4(),
            inputs: inputs
          }
        ];
      });
      this.setState({ inputRows });
    }
  }

  getRegions = async () => {
    this.getRegionsApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getRegionAPiEndPoint,
    }); 
  }

  handleRegionsResponse = (response: RegionsResponse) => {
    if (response.data) {
      const regions = response.data.map((region, index) => {
        return {
          id: region.id,
          value: region.name,
          label: region.name
        }
      });
      this.setState({ regions });
    }
  }

  getEnergyUnits = async () => {
    this.getEnergyUnitsApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getEnergyUnitsAPiEndPoint,
    }); 
  }

  handleEnergyUnitsResponse = (response: EnergyUnitsResponse) => {
    if (response.success) {
      const energyUnits = response.data.map((unit, index) => {
        return {
          id: index + 1,
          value: unit,
          label: unit
        }
      });
      this.setState({ energyUnits });
    }
  }

  generateLabelFromParamName = (paramName: string): string => {
    switch (paramName) {
      case "year":
        return "Year";
      case "region":
        return "Region";
      case "activity_id":
        return "Energy Source";
      case "energy_unit":
        return "Energy Unit";
    
      default:
        return "";
    }
  }

  generateOptionsFromParamName = (paramName: string) => {
    switch (paramName) {
      case "year":
        return years;
      case "region":
        return this.state.regions;
      case "energy_unit":
        return this.state.energyUnits;
      case "activity_id":
        return this.state.energySource;
    
      default:
        return [];
    }
  }

  getEnergySource = async () => {

    this.getEnergySourceApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.getEnergySourceAPiEndPoint,
    }); 
  }

  handleEnergySourceReponse = (response: EnergySourceResponse) => {
    if (response.success) {
      const energySourceOptions = response.data.map(source => {
        return {
          id: source.id,
          value: source.name,
          label: source.name
        }
      });

      this.setState({ energySource: energySourceOptions });
    }
  }

  handleFormSubmit = async (formValue: { inputRows: InputRow[] }) => {
    const apiParams = formValue.inputRows.map(inputRow => {
      const currentEnergySource = inputRow.inputs["activity_id"].value;
      const energySource = this.state.energySource.find(source => source.value === currentEnergySource);
      const region = inputRow.inputs["region"].value;
      const energy = inputRow.inputs["energy"].value;
      const energyUnit = inputRow.inputs["energy_unit"].value;
      
      return {
        region,
        activity_id: energySource?.value,
        activity_code: energySource?.id,
        energy: parseInt(energy),
        energy_unit: energyUnit
      } 
    });

    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    const payload = {
      account_id: userDetails.meta.id,
      monthly_assessment_card_id: this.state.cardId,
      api_params: apiParams,
    }

    this.saveCalculateCo2ApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.calculateCo2APiEndPoint,
      payload: JSON.stringify(payload),
    });
  }

  handleCo2Calculation = (response: {
    data: string;
    success: boolean;
  }) => {
    if (!response.success) {
      this.setState({
        openFailureDialog: true,
        failureMessage: response.data,
      });
      return;
    }
    this.setState({ co2Result: response.data });
  }

  handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const comment = event.target.value.substring(0, 250);
    this.setState({ comment });
  }

  saveMetrics = async () => {
    this.setState({ isLoadingEnergyConsumption: true });
    const formData = new FormData();
    const userDetails = JSON.parse(localStorage.getItem("userDetails") as string);
    formData.append("data[account_id]",  userDetails.meta.id);
    formData.append("data[user_answers][][question_id]",  `${this.state.questionId}`);
    formData.append("data[user_answers][][question_type]",  this.state.questionType);
    formData.append("data[user_answers][][answers]",  this.state.co2Result);
    formData.append("data[activity]",  this.state.comment);
    formData.append("data[task_id]",  `${this.state.taskId}`);
    formData.append("data[card_id]",  `${this.state.cardId}`);

    if (userDetails.meta.user_account_type === "portfolio_manager") {
      const organizationDetails = JSON.parse(localStorage.getItem("organization") as string);
      formData.append("data[company_id]", organizationDetails.id);
    }

    this.saveMetricsApiCallId = await apiCall({
      method: configJSON.postAPiMethod,
      endPoint: configJSON.saveMetricsAPiEndPoint,
      payload: formData,
    });
  }

  handleSaveMetricsResponse = async (response: SaveMetricsResponse) => {
    if (!response.success) {
      const defaultErrorMessage = "An error has occured. Please try again later"
      const failureMessage = response?.errors?.account ?? defaultErrorMessage;
      this.setState({
        failureMessage,
        openFailureDialog: true,
      });
      return;
    }

    this.setState({ 
      openSuccessDialog: true,
      comment: "", 
    });

    const cardDetails = await getStorageData("energyConsumptionPayload");
    const cardDetailsJson = JSON.parse(cardDetails);
    this.getEnergyConsumptionDetails(cardDetailsJson);
  }

  handleCloseFailureDialog = () => {
    this.setState({
      openFailureDialog: false,
      failureMessage: "",
    });
  }

  handleCloseSuccessDialog = () => {
    this.setState({
      openSuccessDialog: false,
    });
  }

  navigateToContactUs = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ContactUsPage");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  // Customizable Area End
}
