import React from "react";
import parse from "html-react-parser";
import { Container, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      paddingBottom: "70px",
      "@media(max-width: 768px)": {
        paddingBottom: "40px",
      },
    },

    blockContainer: {
      "@media(max-width: 768px)": {
        rowGap: "16px",
        padding: "40px 20px 0 20px",
      },
    },

    heading: {
      fontSize: "26px",
      fontWeight: 700,
      textTransform: "capitalize",
      "@media(max-width: 768px)": {
        fontSize: "20px",
        lineHeight: "26px",
      },
    },

    body: {
      marginTop: "12px",
      color: "#686868"
    },

    blockItem1: {
      paddingRight: "30px",
      "@media(max-width: 768px)": {
        paddingRight: "0",
      },
    },

    blockItem2: {
      paddingLeft: "30px",
      "@media(max-width: 768px)": {
        paddingLeft: "0",
      },
    },
  })
);

export default function OurMission({ mission_vision_widget_items }: any) {
  const styles = useStyles();
  const { our_mission, mission_name, our_vision, vision_name } = mission_vision_widget_items?.data[0]?.attributes;

  return (
    <div className={styles.block}>
      <Container>
        <Grid container spacing={4} className={styles.blockContainer}>
          <Grid item xs={12} sm={6}>
            <h2 className={styles.heading}>{mission_name}</h2>
            <p className={styles.body}>
              {parse(our_mission.toString(), { trim: true })}
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2 className={styles.heading}>{vision_name}</h2>
            <p className={styles.body}>
              {parse(our_vision.toString(), { trim: true })}
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
