import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import parse from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontSize: "2rem",
      fontWeight: 700,
      marginBottom: "1rem",
    },

    section: {
      padding: "80px 50px",
      backgroundColor: "transparent",
      overflowX: "hidden",
      width: "100%",
      "@media(max-width: 768px)": {
        padding: "40px 20px",
      },
    },

    heading5: {
      fontSize: "48px",
      fontWeight: 600,
      lineHeight: "32px",
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      textAlign: "center",
      marginBottom: "24px",
      "& > p": {
        fontFamily: "Montserrat"
      },
      "@media (max-width: 768px)": {
        fontSize: "18px",
        lineHeight: "26px",
        marginBottom: "16px",
      }
    },

    frameworks: {
      display: "flex",
      gap: "70px",
      marginTop: "28px",
      width: "2000px",
    },

    specialist: {
      display: "flex",
      gap: "40px",
      alignItems: "center",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      animation: "scroll 20s linear infinite",
    },
    slickList: {
      '@media (min-width:600px)': {
        margin: "0 -10px",
        height: "150px !important",
      },
    },
    sliderContainer: {
      width: "100%",
      overflow: "hidden",
      display: "flex",
      alignItems: "center"
    },
    sliderImageWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "150px",
      padding: "0 15px",
      boxSizing: "border-box"
    },
    sliderImage: {
      maxHeight: "150px", objectFit: "contain"
    }
  })
);

type SpecialistProps = {
  description: string;
  specialist_logo_widget_items: SpecialistLogoWidgetItem[];
};

type SpecialistLogoWidgetItem = {
  id: number;
  attributes: {
    specialist_image: string;
  };
};

export default function GetCertified(props: any) {
  const styles = useStyles();
  const { description, framework_logos } = props;
  const logos = [
    ...framework_logos.data,
    ...framework_logos.data,
  ];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 0,
    pauseOnHover: false,
    cssEase: "linear",
    className: styles.slickList,
    variableWidth: true,
  };

  return (
    <Container className={`${styles.section} certified-logo-container`}>
      <h5 className={styles.heading5}>{parse(description)}</h5>
      <div className={styles.sliderContainer}>
        <Slider {...settings}>
          {logos.map((item: any) => {
            return (
              <div className={styles.sliderImageWrapper}>
                <img 
                  key={item.id}
                  src={item.attributes.logo_framework} 
                  alt="framework" 
                  className={styles.sliderImage}
                />
              </div>
            )
          })}
        </Slider>
      </div>
    </Container>
  );
}
