import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    Typography,
    Divider,
    Avatar,
    ListItemIcon,
    Drawer,
    Box,
    MenuItem,
    Paper,
    IconButton,
    InputBase,
    Breadcrumbs,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles, Theme, createStyles, styled } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { act_new, act_active, expert_new, expert_active, measure, measure_active, overview_new, overview_active, setting_new, setting_active, start_new, transform_new, transform_active, logoLight, start_active, logo } from "./assets"
import MenuIcon from "@material-ui/icons/Menu";
import PortfolioManagerLogin from '../../email-account-login/src/PortfolioManagerLogin.web';
import { getStorageData, setStorageData, removeStorageData } from '../../../framework/src/Utilities';

const crypto = require('crypto');

interface Props {
    navLinks?: string[]
}

interface Path {
    pathname: string;
    title: string;
    link: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "250px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: '10px 10px 50px 0px rgba(100, 116, 139, 0.04)',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            position: 'fixed',
            overflow: 'scroll',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
                width: '0.5em',         /* Width of the scrollbar */
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'white',  /* Color of the scrollbar thumb */
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'white', /* Color of the scrollbar track */
            },
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        drawerPaper: {
            width: 240,
        },
        listLogo: {
            background: "#34373A",
            padding: "20px",
        },
        items: {
            width: "100%",
            maxWidth: '218px',
            height: "48px",
            padding: "0px 16px",
            alignItems: "center",
            gap: '16px',
            borderRadius: "12px",
            display: "flex",
            '&:hover': {
                backgroundColor: '#D1F2DA',
            }
        },
        menuName: {
            color: "#7D7D7F",
            fontFamily: "Lato",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "150%",
            letterSpacing: "0.2px",
            minWidth: '113px',
            '&:hover': {
                color: '#329E54'
            }
        },
        flexLink: {
            display: "flex",
            textDecoration: "none",
            '@media (max-width: 1100px)': {
                '& div:first-child': {
                    width: "100%",
                    display: "flex",
                    justifyContent: " space-between"
                }
            },

        },
        expandIcon: {
            width: "24px",
            height: "24px",
            color: '#7D7D7F'
        },
        link: {
            color: "#7D7D7F",
            textDecoration: 'none',
            fontFamily: "Lato",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            height: "48px",
            paddingLeft: "57px",
            lineHeight: "150%",
            letterSpacing: "0.2px",
        },
        activeLink: {
            background: "#ECFBF1",
            textDecoration: 'none',
            color: "#329E54",
            fontFamily: "Lato",
            borderRadius: "12px",
            fontSize: "15px",
            height: "48px",
            paddingLeft: "57px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "150%",
            letterSpacing: "0.2px",
        },
        breadcrumbs: {
            '& .MuiBreadcrumbs-separator': {
                margin: 0
            }
        },
        Headerlink: {
            textDecoration: 'none',
            display: "inline-flex",
            justifyContent: "center",
            alignItem: "center",
            color: "#34373A",
            fontSize: '14px',
            fontWeight: 400,
            '@media(max-width: 440px)': {
                fontSize: '11px',
                fontWeight: 400,
            },
        },
        logo: {
            margin: '19px 0 0 32px'
        },
        logoContainer: {
            width: '100%',
            backgroundColor: '#F5F5F5',
            position: 'fixed',
            top: 0,
            zIndex: 1,
            borderRight: '1px solid #EBEBEB',
            maxWidth: "250px",
            '@media(max-width: 1100px)': {
                maxWidth: '100px',
            },
        },
        iconContainer: {
            width: '100%',
            backgroundColor: '#F5F5F5',
            maxHeight: '52px',
            '@media(max-width: 1100px)': {
                maxWidth: '100px',
                marginRight: '10px'
            },
            '@media(max-width: 600px)': {
                marginRight: '14px'
            },
        },
        userProfile: {
            padding: '20px 16px 20px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
        },
        userName: {
            color: "#34373A",
            fontFamily: "Lato",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "150%",
            '@media (max-width:600px)':{
                marginLeft:'20px'
                    }
        },
        breadCrumbs:{
            '@media (max-width:600px)':{
            display: 'block !important',
             width: '200px',
                }
        },
        designation: {
            color: "#7D7D7F",
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",
        },
        userProfilMobile: {
            padding: '20px 16px 20px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
        },
        userNameMobile: {
            color: "#34373A",
            fontFamily: "Lato",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "150%",
        },
        userDesignation: {
            color: "#7D7D7F",
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",
        },
        toolbarItem: {
            padding: 0,
            margin: 0,
        },
        subMenuLabel: {
            background: '#efefef',
            '& li': {
                minHeight: '20px',
                lineHeight: '16px',
                fontSize: '16px'
            },
        },
        ListText: {
            color: "#34373A",
            backgroundColor: "#7F7F7F",
            textDecoration: "none",
        },
        iconButtonStyle: {
            border: "1px solid #34373A",
            borderRadius: "8px",
            backgroundColor: '#F5F5F5',
            marginBottom: '20px'
        },
        serchbarRoot: {
            width: '100%',
            maxWidth: `calc(100% - 250px)`,
            backgroundColor: '#F5F5F5',
            maxHeight: '135px',
            position: 'fixed',
            top: '0px',
            right: "0px",
            zIndex: 2,
            height: '80px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 30px',
            gap: "20px",
            alignItems: 'center',
            borderBottom: '1px solid #EBEBEB',
            '@media(max-width: 1100px)': {
                maxWidth: '100%',
            },
            '@media(max-width: 600px)': {
                paddingRight:'22px',
                paddingLeft:'15px'
            },
            [theme.breakpoints.up("sm")]: {
                maxHeight: 67,
            }
        },
        searchBoxOne:{
            display:'flex',
            alignItems:'center',
            gap:'20px',
            '@media(max-width: 600px)': {
                gap:'10px'
            },
        },
        paper: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: "900px",
            borderRadius: "8px",
            border: "1px solid #D9D9D9",
            height: "48px",
            width: '900px'
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 24,
            height: 24,
            color: '#000000'
        },
        icons: {
            minWidth: '24px'
        },
        backLink: {
            textDecoration: 'none',
            color: "#34373A",
            display: 'flex',
            alignItems: "self-start",
            fontFamily: "Lato",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "0.2px"
        },
        option123: {
            color: '#329E54',
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            paddingBottom: "10px",
            lineHeight: "160%",
            textDecoration: "none",
            cursor: "pointer",
        },
        sidebar : {
            marginTop: '80px',
            padding: '0 16px',
        }
    }),
);

const ExpandLessIcon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 15L12 9L6 15" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    )
}

const ExpandMoreIcon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 9L12 15L18 9" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    )
}


const HiddenBox1 = styled('div')({
    width: '100%',
    '@media(max-width: 1100px)': {
        display: 'none',
    },
});

const HiddenBox2 = styled('div')({
    '@media(min-width: 1100px)': {
        display: 'none',
    },
});

const CustomBreadcrumps = styled(Breadcrumbs)({
    fontSize: '14px',
    color: "#34373A",
    '@media(max-width: 440px)': {
        fontSize: '10px',
        width:'142px'
        
    },
});

export const capitalizeAndReplace = (pathlink: string) => pathlink.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

export const ListRender = (props: any) => {
    const { headerDataFromApi, handleDrawerClose } = props;
    const [expandedState, setExpandedState] = useState<any>({});
    const classes = useStyles();
    const handleExpand = (key: string) => {
        setExpandedState((prevState: any) => {
            const newState = { ...prevState };
            Object.keys(newState).forEach(itemKey => {
                newState[itemKey] = false;
            });
            newState[key] = !prevState[key];
            return newState;
        });
    };
    return (
        <div>
            <ul className={classes.toolbarItem}>
                {headerDataFromApi && headerDataFromApi.length > 0 && headerDataFromApi.map((item: any, index: number) => {
                    if(item.divider) {
                        return <Divider />
                    }
                    if (item.isSubItem) {
                        const isExpanded = expandedState[item.name];
                        return (
                            <div key={`lim${index}`} data-test-id="handle-function-click">
                                <MenuItem data-test-id="menu-item-clicks"onClick={() => handleExpand(item.name)}>
                                      {item.name}
                                    <ExpandMoreIcon />
                                </MenuItem>
                                <div className={classes.subMenuLabel}>
                                    {isExpanded &&
                                        item.subItems && item.subItems.map((option: any, subIndex: number) => (
                                            <MenuItem
                                                component={Link}
                                                to={props.handlegetLink(option.link)}
                                                data-test-id="menuItem"
                                                onClick={() => {
                                                    handleDrawerClose();
                                                    setStorageData("activeSidebarLink", option.link);
                                                }}
                                                key={`subItem${subIndex}`}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <Link
                                key={`link${index}`}
                                to={props.handlegetLink(item.link)}
                                className={classes.ListText}
                                data-test-id="handle-link"
                            >
                                <MenuItem data-test-id="testId1" onClick={() => { handleDrawerClose() }}>
                                {item.name}
                                   </MenuItem>
                            </Link>
                        );
                    }
                })}
            </ul>
        </div>
    )
};

export const RenderMenuItem = (props: any) => {
    let { classes, handleOptions } = props

    const handleLogout = () => {
        localStorage.clear();
        window.location.replace("/");
    };

    return <div style={{
        width: "100px",
        height: "78px",
        borderRadius: "12px",
        background: "#ECFBF1",
        boxShadow: "none",
        position: "absolute", right: "2px", padding: "16px", marginTop: '8px'
    }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Link className={classes.option123} to={props.handlegetLink("/settings/organization-info")} data-test-id="profileClick" onClick={handleOptions} >Profile</Link>
            <Link className={classes.option123} to="/" data-test-id="logoutClick" onClick={handleLogout}>Logout</Link>
        </div>
    </div>

}

const Sidebar = ({ navLinks }: Props) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const MenuItem = [
        {
            name: 'To Do List',
            isSubItem: false,
            icon: start_new,
            activeIcon: start_active,
            link: '/get-started'
        },
        {
            name: 'Overview',
            isSubItem: true,
            icon: overview_new,
            activeIcon: overview_active,
            link: '/overview',
            subItems: [
                {
                    name: "Dashboard",
                    link: '/overview/dashboard'
                },
                // {
                //     name: "ESG Vault",
                //     link: '/overview/esg-vault'
                // },
            ]
        },
        {
            name: 'Measure',
            isSubItem: true,
            icon: measure,
            activeIcon: measure_active,
            link: '/measure',
            subItems: [
                {
                    name: "UN SDG Goals",
                    link: '/measure/all-unsdg-goals'
                },
                {
                    name: "Core Metrics",
                    link: '/measure/log-your-esg-factors/core'
                },
                {
                    name: "Materiality Topics",
                    link: '/measure/log-your-esg-factors/all'
                },
            ]
        },
        {
            name: 'Act',
            isSubItem: true,
            icon: act_new,
            activeIcon: act_active,
            link: '/act',
            subItems: [
                {
                    name: "Forecast Analysis",
                    link: '/act/forecast-Analysis'
                },
                {
                    name: "Action Plan",
                    link: '/act/action-plan'
                },
            ]
        },
        {
            name: 'Transform',
            isSubItem: true,
            hasNestedSubItems: true,
            icon: transform_new,
            activeIcon: transform_active,
            link: '/transform',
            subItems: [
                {
                    name: "Hub",
                    link: '/transform/hub/marketplace',
                },
                {
                    name: "Reporting",
                    link: '/transform/reporting/selected-unsdgs',
                },
            ]
        },
        { divider: true },
        {
            name: 'Climate Concierge',
            isSubItem: false,
            icon: expert_new,
            activeIcon: expert_active,
            link: '/climate-concierge/portfolio-manager',
        },
        {
            name: 'Settings',
            isSubItem: true,
            icon: setting_new,
            activeIcon: setting_active,
            link: '/settings',
            subItems: [
                {
                    name: "Plan & Billing",
                    link: '/settings/plan&billing/select-plan'
                },
                {
                    name: "Users",
                    link: "/settings/users"
                }
            ]
        },
    ]
    const [Menu,setMenu] = useState(MenuItem)
    const [openDiv, setOpenDiv] = useState(false);
    const [subItemsOpen, setSubItemsOpen] = useState<{ [key: number]: boolean }>({});
    const [SecondsubItemsOpen, setSecondSubItemsOpen] = useState<{ [key: number]: boolean }>({});
    const [isopen, setIsopen] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState('');
    const [organizationError, setOrganizationsError] = useState('');
    const [accountDtails,setAccountDetails] = useState<any>("")
    const dynamicPathname = window.location.pathname
    const defualtpage = "/access-restricted";
    const isDetails = dynamicPathname.includes('/details')
    const isplans = dynamicPathname != defualtpage
    const twopages = !isDetails || !isplans
    const organization = localStorage.getItem('organization')
    const account_type = JSON.parse(localStorage.getItem("userDetails") as string)?.meta?.user_account_type
    useEffect(() => {
        const accountDtails = JSON.parse(localStorage.getItem("userDetails") as string)?.meta
        setAccountDetails(accountDtails)
        
    },[])
    useEffect(() =>{
        if (accountDtails?.user_account_type === 'portfolio_manager') {
            setMenu((prevMenu) =>
                prevMenu.map((menuItem) =>
                    menuItem.name === 'Measure'
                        ? {
                              ...menuItem,
                              subItems: [
                                  ...(menuItem.subItems || []),
                                  { name: 'Climatic Goals', link: '/measure/climatic-goals' },
                              ],
                          }
                        : menuItem
                )
            );
        }
    },[accountDtails?.user_account_type === 'portfolio_manager'])

    useEffect(() => {
        if (navLinks?.length && !navLinks?.includes(dynamicPathname) && twopages) {
            window.location.replace(defualtpage)
        }
    }, [dynamicPathname])

    useEffect(() => {
        getOrganization()
    }, [organization])


        const getOrganization = async () => {
        const organizations = await getStorageData('organization', true)
        if (!organizations && account_type === "portfolio_manager") {
            setIsopen(true)
        } else {
            setOrganizationsError("")
            setOrganizations(organizations?.name)
        }
    }

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleOptions = () => {
        setOpenDiv(!openDiv);
    }

    const getDesignation = (userRole:string) => {
        if(userRole === "primary_user") return "ESG Admin"
        else if(userRole === "secondary_user") return "ESG User"
        else return "ESG Portfolio Manager"
    }

    // Step 1: Menu1, Step 2: Menu2
    const handleSubItemsToggle = (index: number) => () => {
        setSubItemsOpen((prevState) => {
            const newState = Object.keys(prevState).reduce((acc: any, key) => {
                acc[key] = false;
                return acc;
            }, {} as { [key: number]: boolean });
            newState[index] = !prevState[index];
            return newState;
        });
    };

    const reportPage = (currentPath: string) => {
        return "/transform/" && currentPath.split("/")[2] === "reporting-framework" ||
            "/transform/" && currentPath.split("/")[2] === "reports"
    }

    const settingPage = (currentPath: string) => {
        return currentPath.split("/")[2] === "organization-info" ||
            currentPath.split("/")[2] === "profile" ||
            currentPath.split("/")[2] === "plan-&-billing" ||
            currentPath.split("/")[2] === "users"
    }

    const handlegetLink = (link: string) => {
        if (navLinks?.includes(link)) {
            return link
        } else {
            return defualtpage
        }
    }


    const createSubItemsCollapse = (index: number, item: any, step: number, currentPath: string) => {
        const { subItems } = item;
        // Step 1: Menu1, step 2: Menu2
        let openSubMenus = step === 1 ? subItemsOpen[index] : SecondsubItemsOpen[index];
        const ESG_factors = [
            "/measure/log-your-esg-factors/all",
            "/measure/log-your-esg-factors/organization",
            "/measure/log-your-esg-factors/environment",
            "/measure/log-your-esg-factors/social",
            "/measure/log-your-esg-factors/governance",
            "/measure/log-your-esg-factors/completed",
            "/measure/log-your-esg-factors/archived",
            "/measure/log-your-esg-factors/recommended"
        ];

        if (!openSubMenus) {
            openSubMenus = item.link.includes(dynamicPathname.split("/")[1])
        }
        return <Collapse in={openSubMenus} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {
                    subItems.map((item: any) => {
                        const activeSidebarLink = localStorage.getItem("activeSidebarLink") as string;
                        const activeSidebarLinkArr = activeSidebarLink ? activeSidebarLink.split("/") : [];
                        const esgType = activeSidebarLinkArr[activeSidebarLinkArr.length - 1];
                        let linkMatch = (item.link && item.link !== "#") && currentPath.includes(item.link.split("/").slice(1, 3).join("/"))
                        if (item.link === "/measure/log-your-esg-factors/core" && currentPath.includes("/measure/log-your-esg-factors")) {
                            linkMatch = esgType === "core";
                        } else if (item.link === "/measure/log-your-esg-factors/all" && currentPath.includes("/measure/log-your-esg-factors")) {
                            linkMatch = esgType === "all";
                        } else if (item.link.includes("core")) {
                            linkMatch = linkMatch && currentPath.split("/")[3] === "core" || currentPath.split("/")[5] === "null"
                            if (
                                currentPath === "/measure/log-your-esg-factor/energy-consumption"
                                || currentPath === "/measure/log-your-esg-factor/shipping-and-freight"
                            ) {
                                linkMatch = true;
                            }
                        } else if (item.link.includes("all") && !item.link.includes("all-unsdg-goals")) {
                            // Remove any trailing slashes before searching in ESG_factors
                            linkMatch = linkMatch && ESG_factors.includes(currentPath.replace(/\/$/, '')) || currentPath.split("/")[8] === "null"
                        } else if (item.link.includes("reports")) {
                            linkMatch = reportPage(currentPath)
                        } else if (item.link.includes("settings") && !item.link.includes("plan&billing")) {
                            linkMatch = settingPage(currentPath)
                        }

                        return <ListItem onClick={() => setStorageData("activeSidebarLink", item.link)} to={handlegetLink(item.link)} className={linkMatch ? classes.activeLink : classes.link} component={Link}>
                            {item.name}
                        </ListItem>
                    })
                }
            </List>
        </Collapse>
    }

    const Paths = [
        { pathname: "get-started", title: "Get started", link: '/get-started' },
        { pathname: "measure", title: "Measure", link: '/measure/all-unsdg-goals' },
        { pathname: "map-your-organization", title: "Map your organization", link: '/get-started/map-your-organization/organization-info' },
        { pathname: "log-your-esg-factors", title: "Log your emissions", link: '/measure/log-your-esg-factors/all' },
        { pathname: "organization-info", title: "Organization Info", link: '/get-started/map-your-organization/organization-info' },
        { pathname: "users", title: "Users", link: '/get-started/map-your-organization/users' },
        { pathname: "plan-&-billing", title: "Plan & Billing", link: '/settings/plan-&-billing' },
        { pathname: "dashboard", title: "Dashboard", link: '/overview/dashboard' },
        { pathname: "overview", title: "Overview", link: '/overview/dashboard' },
        { pathname: "hub", title: "Hub", link: '/transform/hub/marketplace' },
        { pathname: "marketplace", title: "Marketplace", link: '/transform/hub/marketplace' },
        { pathname: "act", title: "Act", link: '/act/forecast-Analysis' },
        { pathname: "transform", title: "Transform", link: '/transform/hub/marketplace' },
        { pathname: "setting", title: "Setting", link: "settings/Profile" },
        { pathname: "organiZationInfo", title: "OrganiZationInfo", link: '/settings/organization-info' },
        { pathname: "settings", title: "Settings", link: '/settings/organization-info' },
        { pathname: "plan&billing", title: "Plan & Billing", link: '/settings/plan&billing/select-plan' },
        { pathname: "hubdetail", title: "Hub", link: '/transform/hub/marketplace' },
        { pathname: "climate-concierge", title: "Climate Concierge", link: '/climate-concierge/portfolio-manager' },
    ];

    const getPathname = (pathlink: string) => {
        const foundPath = Paths.find((item: Path) => pathlink === item.pathname)
        if (foundPath) {
            return foundPath.title;
        }
        return capitalizeAndReplace(pathlink)
    }

    const getPathLink = (pathlink: string) => {
            return Paths.find((item: Path) => pathlink === item.pathname)?.link
    }

    const getIcon = (index: number, item: { link: string }, step: number) => {
        let openSubMenus = step === 1 ? subItemsOpen[index] : SecondsubItemsOpen[index];
        if (!openSubMenus) {
            openSubMenus = item.link.includes(dynamicPathname.split("/")[1])
        }
        return openSubMenus
    }

    const invalidPaths = ["details", 'all', 'organization', 'environment', 'social', 'governance', 'completed', 'archived', 'recommended', "core"]

    const onClose = () => {
        if (!organizations && account_type === "portfolio_manager") {
            setOrganizationsError("please select organization")
        } else {
            setOrganizationsError("")
            setIsopen(false)
        }
    }

    const onOpenOrganozationOpen = () => {
        setIsopen(true)
    }

    return (
        <>
            <PortfolioManagerLogin navigation={undefined} id={''} open={isopen} onClose={onClose} error={organizationError} />
            <HiddenBox1 style={{ backgroundColor: 'red' }}>
                <div className={classes.logoContainer}>
                    <Link to="/">
                        <img data-test-id="logo" src={logoLight} className={classes.logo} alt='logo' width={144} height={22} />
                    </Link>
                    <Divider style={{ marginTop: '25px', backgroundColor: '#EBEBEB' }} />
                </div>
            </HiddenBox1>
            <div className={classes.serchbarRoot}>
                <div className={classes.searchBoxOne}>
                <HiddenBox2>
                    <div className={classes.iconContainer}>
                        <IconButton
                            className={classes.iconButtonStyle}
                            data-test-id="toggle-button-test"
                            color={'default'}
                            aria-label="menu"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </HiddenBox2>
                <CustomBreadcrumps aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link color="inherit" to={"/get-started"} className={classes.Headerlink}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 3L3 11.6087H5.34783V21H18.6522V11.6087H21L12 3Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 16H10V21H14V16Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Link>
                    {
                        dynamicPathname.split("/").filter((items, index) => index !== 0 && index < 4 && !invalidPaths.includes(items)).map((item: string) => {
                            const link = getPathLink(item);
                            if (link) {
                                return <Link
                                    color="inherit"
                                    to={link}
                                    className={classes.Headerlink}
                                >
                                    {getPathname(item)}
                                </Link>
                            } else {
                                return <span
                                    className={classes.Headerlink}
                                >
                                    {getPathname(item)}
                                </span>
                            }
                        }
                        )
                    }
                </CustomBreadcrumps>
                </div>
                {
                    account_type === "portfolio_manager" &&
                    <div>
                        {
                            organization ?
                                <div style={{ display: 'flex', alignItems: 'baseline', gap: '7px' }} className={classes.breadCrumbs}>
                                    <Typography 
                                        className={classes.Headerlink} 
                                        style={{ fontWeight: 700, fontFamily: 'Montserrat' }} 
                                        data-test-id="portfolioopen"
                                    >
                                        Selected Organization:
                                    </Typography>
                                    <Typography 
                                        className={classes.userName} 
                                        data-test-id="portfolioopen" 
                                        style={{ 
                                            cursor: 'pointer', 
                                            color: '#43D270', 
                                            fontWeight: 700,
                                            fontFamily: 'Montserrat'
                                        }} 
                                        onClick={onOpenOrganozationOpen}
                                    >
                                        {organizations}
                                    </Typography>
                                </div>
                                : <MoreHorizIcon data-test-id="portfolioopen" onClick={onOpenOrganozationOpen} style={{ color: '#34373A', fontSize: 22, cursor: 'pointer' }} />
                        }
                    </div>
                }
            </div>
            <HiddenBox1>
                <div className={classes.root}>
                    <div>
                        <List className={classes.sidebar} data-test-id={'list-item'}>
                            {Menu.map((item, index) => {
                                  const isExpert = item.link?.includes("climate-concierge") && dynamicPathname.split("/")[1] === "climate-concierge"
                                  const isActiveItem = "/" + dynamicPathname.split("/")[1] === item.link && item.link == "/get-started" || isExpert;
                                const linkItemStyle = { backgroundColor: isActiveItem ? '#ECFBF1' : 'white' }
                                const linkIcon = "/" + dynamicPathname.split("/")[1] === item.link ? item.activeIcon : item.icon;
                                const linkItemTextStyle = { color: isActiveItem ? '#329E54' : '#7D7D7F' }

                                if(item.divider) {
                                    return <Divider style={{ marginTop: '20px' }} />
                                }
                                return <div key={index}>
                                    {item.isSubItem ?
                                        <>
                                            <ListItem 
                                                className={classes.items}
                                                style={linkItemStyle}
                                                button 
                                                onClick={handleSubItemsToggle(index)}
                                                data-test-id={'list-item-toggle'}
                                            >
                                                <ListItemIcon className={classes.icons}>
                                                    <img src={"/" + dynamicPathname.split("/")[1] === item.link && (dynamicPathname.split("/").length < 2) ? item.activeIcon : item.icon} height={24} width={24} alt="icon" />
                                                   
                                                </ListItemIcon>
                                                <ListItemText 
                                                    primary={
                                                        <Typography 
                                                            className={classes.menuName}
                                                            style={linkItemTextStyle}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    } 
                                                />
                                                {getIcon(index, item, 1) ?
                                                    <ExpandLessIcon /> :
                                                    <ExpandMoreIcon />
                                                }

                                            </ListItem>
                                            {createSubItemsCollapse(index, item, 1, dynamicPathname)}
                                        </>
                                        : <>
                                            <ListItem className={classes.items}
                                                style={linkItemStyle}
                                                button
                                                to={handlegetLink(item.link ?? "")}
                                                component={Link}
                                            >
                                                <ListItemIcon className={classes.icons}>
                                                    <img src={linkIcon} height={24} width={24} alt="icon" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography className={classes.menuName}
                                                    style={linkItemTextStyle}>
                                                    {item.name}
                                                </Typography>} />
                                            </ListItem>
                                        </>
                                    }
                                </div>
                            }
                            )}
                        </List>
                    </div>
                    <div >
                        {openDiv && <RenderMenuItem classes={classes} handleOptions={handleOptions} handlegetLink={handlegetLink} />}
                        <Divider style={{ marginTop: '64px' }} />
                        <div className={classes.userProfile}>
                            <div style={{ display: 'flex' }}>
                                {!accountDtails?.photo ? <Avatar style={{ marginRight: '10px', height: '36px', width: '36px' }} > {accountDtails?.first_name?.charAt(0).toUpperCase()} </Avatar> : <Avatar src={accountDtails?.photo} alt={accountDtails?.userName} style={{ marginRight: '10px', height: '36px', width: '36px' }} />}
                                <div>
                                    <Typography className={classes.userName}>{accountDtails?.first_name + " " + accountDtails?.last_name}</Typography>
                                    <Typography data-test-id="user-role-name-desktop" className={classes.designation}>
                                        {getDesignation(accountDtails?.user_account_type)}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} data-test-id="clickdots" onClick={handleOptions}>
                                <MoreHorizIcon />
                            </div>
                        </div>
                    </div>
                </div >
            </HiddenBox1 >
            <HiddenBox2>
                <Drawer
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    classes={{ paper: classes.drawerPaper }}
                    data-test-id="drawer-close"
                > 
                <div className={classes.root}>
                    <Box>
                        <div className={classes.listLogo}>
                       <Link to="/" className={classes.flexLink}>   
                                <img src={logo} style={{ width: "100%"}} />
                            </Link>
                        </div>
                        <ListRender
                            handleDrawerClose={handleDrawerClose}
                            headerDataFromApi={Menu}
                            handlegetLink={handlegetLink}
                        />
                    </Box>
                    <div >
                        {openDiv && <RenderMenuItem classes={classes} handleOptions={handleOptions} handlegetLink={handlegetLink} />}
                        <Divider style={{ marginTop: '64px' }} />
                        <div className={classes.userProfilMobile}>
                            <div style={{ display: 'flex' }}>
                                {!accountDtails?.photo ? <Avatar style={{ marginRight: '10px', height: '36px', width: '36px' }} > {accountDtails?.first_name?.charAt(0).toUpperCase()} </Avatar> : <Avatar src={accountDtails?.photo} alt={accountDtails?.userName} style={{ marginRight: '10px', height: '36px', width: '36px' }} />}
                                <div>
                                    <Typography className={classes.userNameMobile}>{accountDtails?.first_name + " " + accountDtails?.last_name}</Typography>
                                    <Typography data-test-id="user-role-name-mobile" className={classes.userDesignation}>
                                    {getDesignation(accountDtails?.user_account_type)}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} data-test-id="clickdots" onClick={handleOptions}>
                                <MoreHorizIcon />
                            </div>
                        </div>
                    </div>
                    </div>
                    </Drawer>
                </HiddenBox2>
        </>
    );
};

export default Sidebar;
