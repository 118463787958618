import React from "react";
// Customizable Area Start
import parse from 'html-react-parser';
import {
    Box,
    Button,
    Typography,
    Grid,
    Container
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {withRouter} from "react-router-dom"
 const theme = createTheme({
    typography:{
        fontFamily:'Lato'
    }
 })
// Customizable Area End

import CarbonzeroedController, {
    Props,
} from "./CarbonzeroedController";

export class Carbonzeroed extends CarbonzeroedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box className={classes.mainContainer}>
                    <Typography className={classes.pageHeading}
                                data-testid="heading">{this.props.page_heading}</Typography>
                </Box>
            <Container className={classes.mainGrid}>
                <Grid item xs={12}>
                    <Box className={classes.buttonDisplay}>
                    {this.props.headings.map((head,index) =>
                        <Button 
                            variant="outlined" 
                            data-testid={"button-" + index}
                            key={index} 
                            className={head.replace(' ','').toLowerCase() === this.state.tabContent ? classes.activeButton : classes.button} 
                            onClick={()=>this.handleTabs(head.replace(' ','').toLowerCase())}
                        >
                        {head}
                        </Button>
                    )}
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.subItemsContainer}>
                {this.props.widget_sub_items.data.filter(data => data.attributes.title.toLowerCase().replace(' ','') === this.state.tabContent).map(item =>
                    <Box className={classes.imageFlotBox}>
                        <img
                        className={classes.imageStyle}
                        src={item.attributes.image}
                        alt={item.attributes.title}
                        />
                    <Box>
                        <Typography className={classes.title} data-testid="sub-items-title">{item.attributes.title}</Typography>
                        <Typography className={classes.description}>{parse(item?.attributes?.description.toString())}</Typography>
                        <Typography className={classes.fullDescription}>{parse(item.attributes.full_description.toString())}</Typography>
                    </Box>
                    </Box>
                )
                }
                </Grid>
            </Container>
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    mainGrid:{
        textAlign:'center'
    },
    mainContainer: {
        background:'#E6EBFF',
        textAlign:'center'
    },
    pageHeading:{
        fontSize:'3rem',
        fontWeight:800,
        padding:'70px 40px',
        textAlign:'center'
    },
    secondContainer:{
        gap:'20px'
    },
    buttonDisplay:{
        display:'flex',
        justifyContent:'space-between',
        flexWrap:'wrap',
        margin:'40px 10px'
    },
    button:{
        border:'1px solid #4A4A4A',
        borderRadius:'12px',
        width:'381px',
        height:'77px',
        fontFamily:'Lato',
        fontSize:'24px',
        textTransform:'capitalize',
        cursor:'pointer',
        margin:'10px 0',
        "&:hover": {
            backgroundColor: "#43D270",
            border:'1px solid #43D270',
            color:'#262626',
            fontWeight:700
        },
        "@media (max-width: 850px)": {
            width: "100%",
        }
    },
    activeButton:{
        backgroundColor: "#43D270",
        border:'1px solid #43D270',
        color:'#262626',
        fontWeight:700,
        borderRadius:'12px',
        width:'381px',
        height:'77px',
        fontFamily:'Lato',
        fontSize:'24px',
        textTransform:'capitalize',
        margin:'10px 0',
        "&:hover": {
            backgroundColor: "#43D270",
            border:'1px solid #43D270',
            color:'#262626',
        },
        "@media (max-width: 850px)": {
            width: "100%",
        }
    },
    description:{
        "&, & > p": {
            fontSize:'2rem',
            fontWeight:700,
            lineHeight:'48.4px',
            marginBottom:'20px'
        }
    },
    subItemsContainer:{
        margin:"20px 10px",
        textAlign:'start'
    },
    title:{
        fontSize:'1.2rem',
        textTransform:'uppercase',
        marginBottom:'8px'
    },
    imageStyle:{
        width:'min(100%,618px)',
        height:'376px',
        objectFit:'contain',
        '@media (min-width: 950px)':{
            float: "right",
            marginLeft: "1rem",   
        },
        '@media only screen and (min-device-width: 414px) and (max-device-width: 950px)':{
            width:'min(100%,784px)',
            marginBottom: '10px',
            height: "auto"
        },
        '@media (max-width: 414px)': {
            height: "auto",
            marginBottom: '10px',
        }
    },
    fullDescription:{
        '& p':{
            fontSize:'1.12rem',
            lineHeight:'28.8px',
            color:'#353535',
            paddingBottom:'20px'
        }
    },
    imageFlotBox:{
        minHeight:'376px'
    }
});
const NewCarbonzeroeed = withRouter(Carbonzeroed)
export default ( withStyles(webStyle)(NewCarbonzeroeed));

// Customizable Area End