import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BlogPageHeadingSectionWeb from "../../DynamicContent/src/BlogPageHeadingSection.web";
import CallToActionSection from "../../DynamicContent/src/CallToActionSection.web";
import BlogListingSection from "../../DynamicContent/src/BlogListingSection.web";
import BlogListingEventSection from "../../DynamicContent/src/BlogListingEventSection.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const getBlogHeadingColors = (pathnameBlog: string) => {
  switch (pathnameBlog) {
    case "/events":
      return {
        bgColor: "#E0E0FC",
        tagColor: "#43D270",
        primaryButtonColor: "#FFFFFF",
        secondaryButtonBg: "#FFFFFF"
      };
    case "/insights-and-trends":
    case "/marketplace":
      return {
        bgColor: "#ECFBF1",
        tagColor: "#43D270",
        primaryButtonColor: "#101010",
        secondaryButtonBg: "#FFFFFF"
      };
    case "/job-openings-list":
      return {
        tagColor: "#43D270",
        bgColor: "#ECFBF1",
        secondaryButtonBg: "#FFFFFF",
        primaryButtonColor: "#000000",
      };
    default:
      return {
        bgColor: "#F4DCD4",
        tagColor: "#F96C30",
        primaryButtonColor: "#FFFFFF",
        secondaryButtonBg: "#FFFFFF"
      }
  }

}

// Customizable Area End

import BlogPostsManagementController, {
  Props,
} from "./BlogPostsManagementController";
import BlogDetails from "../../../components/src/BlogPages/BlogDetails.web";
import BlogListingMarketplaceSection from "../../../components/src/BlogListingMarketplaceSection.web"
import EventBlogDetails from "../../../components/src/BlogPages/EventBlogDetails.web";
import MarketplaceBlogDetails from "../../../components/src/BlogPages/MarketplaceBlogDetails.web";

export default class BlogPostsManagement extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          (this.state.pageHeading || this.state.pageSubHeading) &&
          <Box style={webStyle.pageTitleContainer as React.CSSProperties}>
              <Typography style={webStyle.pageTitle as React.CSSProperties}>
                {this.state.pageHeading}
              </Typography>
              <Typography style={webStyle.pageDescription as React.CSSProperties}>
                {this.state.pageSubHeading}
              </Typography>
          </Box>
        }
        <div data-test-id="widget-wrapper">
          {this.state.blogId == 0 && this.state.blogPageData &&
            this.state.blogPageData.map((page: any, index: number) => {
              let check=page.id === this.state.pageId ? this.state.searchResultData : page?.attributes;
              switch (page?.attributes?.static_widget_code) {
                case "widget-6":
                  const {
                    page: pageName,
                    header,
                    description,
                    widget_item_buttons,
                    page_heading,
                    page_sub_heading,
                    image,
                  } = page?.attributes;
                  const hColor = getBlogHeadingColors(this.state.pathName);
                  return (
                      <BlogPageHeadingSectionWeb
                        key={`blog_widget_key${index}`}
                        image={image}
                        tag={pageName}
                        heading={header}
                        bgColor={hColor.bgColor}
                        tagColor={hColor.tagColor}
                        primaryButtonColor={hColor.primaryButtonColor}
                        primaryButtonBg={hColor.tagColor}
                        secondaryButtonBg={hColor.secondaryButtonBg}
                        description={description}
                        button1Label={widget_item_buttons?.data[0]?.attributes?.name}
                        button2Label={widget_item_buttons?.data[1]?.attributes?.name}
                        button1Link={widget_item_buttons?.data[0]?.attributes?.link}
                        button2Link={widget_item_buttons?.data[1]?.attributes?.link}
                        {...page?.attributes}
                      />
                  );
                case "widget-8":
                  return (
                    <CallToActionSection
                      key={`blog_widget_key${index}`}
                      headingType={'lg'}
                      heading={page?.attributes?.header}
                      themeMode={'dark'}
                      inputPlaceholder={"Enter your E-mail"}
                      buttonLabel={page?.attributes?.widget_item_buttons?.data[0]?.attributes?.name}
                      buttonLink={page?.attributes?.widget_item_buttons?.data[0]?.attributes?.link}
                      onPrivacyPolicyClick={this.navigateToPrivacyPolicyPage}
                      {...page?.attributes}
                    />
                  );
                case "widget-11":
                  return (
                    <BlogListingSection
                      blogsRef={this.blogsRef}
                      key={`blog_widget_key${index}`}
                      header={page?.attributes.header}
                      enableMetaTags={true}
                      data={check}
                      limit={6}
                      handleChangeSearch={this.handleChangeSearch}
                      handleChangeSort={this.handleChangeSort}
                      viewDetails={this.navigateToDetails}
                      handlePageChange={this.handlePageChange}
                      pageId={page.id}
                      isLoading={this.state.isLoading}
                      currentPage={this.state.page}
                      searchString={this.state.searchString}
                    />
                  );
                case "widget-12":
                  return (
                    <BlogListingEventSection
                      enableMetaTags
                      blogsRef={this.blogsRef}
                      key={`blog_widget_key${index}`}
                      header={page?.attributes.header}
                      enablePaymentTags={false}
                      data={check}
                      handleChangeSearch={this.handleChangeSearch}
                      handleChangeSort={this.handleChangeSort}
                      viewDetails={this.navigateToDetails}
                      handlePageChange={this.handlePageChange}
                      pageId={page.id}
                      limit={6}
                      isLoading={this.state.isLoading}
                      currentPage={this.state.page}
                      searchString={this.state.searchString}
                    />
                  );
                case "widget-13":
                  return (
                    <BlogListingMarketplaceSection
                      header={page?.attributes.header}
                      page={page?.attributes?.page}
                      key={`blog_widget_key${index}`}
                      enableButton={true}
                      data={check}
                      handleChangeSearch={this.handleChangeSearch}
                      handleChangeSort={this.handleChangeSort}
                      viewDetails={this.navigateToDetails}
                      handlePageChange={this.handlePageChange}
                      pageId={page.id}
                      isLoading={this.state.isLoading}
                      currentPage={this.state.page}
                      searchString={this.state.searchString}
                    />
                  );
                default:
                  return (
                    <div
                      data-test-id="widget not found"
                      key={`blog_widget_key${index}`}
                    >
                    </div>
                  );
              }
            })}
          {
            !this.state.blogId &&
            this.state.blogPageData &&
            this.state.blogPageData.length === 0 &&
            <div
              style={{ color: "grey", height: "calc(100vh - 145px)", display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <CircularProgress color="inherit" />
            </div>
          }

          {this.state.blogId > 0 && window.location.pathname.includes("insights-and-trends") &&
            <>
              <BlogDetails
                data-test-id="blog-details-test"
                isLoading={this.state.isLoadingBlogDetails}
                data={this.state.blogDetails}
                postType={window.location.pathname.toLowerCase().replace(/[0-9/]+/g, '')}
                viewDetails={(id: number, path: string, relatedPost: boolean) => { this.navigateToDetails(id, path, relatedPost); }}
              />
            </>
          }

          {this.state.blogId > 0 && window.location.pathname.includes("events") &&
            <EventBlogDetails
              data-test-id="event-blog-details-test"
              isLoading={this.state.isLoadingBlogDetails}
              data={this.state.blogDetails}
              postType={window.location.pathname.toLowerCase().replace(/[0-9/]+/g, '')}
              viewDetails={(id: number, path: string, relatedPost: boolean) => { this.navigateToDetails(id, path, relatedPost); }}
            />
          }

          {this.state.blogId > 0 && window.location.pathname.includes("marketplace") &&
              <MarketplaceBlogDetails
                  data-test-id="marketplace-blog-details-test"
                  data={this.state.blogDetails}
                  postType={window.location.pathname.toLowerCase().replace(/[0-9/]+/g, '')}
                  navigate={this.navigateToContactUsPage}
                  viewDetails={(id: number, path: string, relatedPost: boolean) => {
                    this.navigateToDetails(id, path, relatedPost);
                  }}
              />
          }
        </div>
        <Button data-test-id={'newBtn'} style={{ display: 'none' }} {...this.btnShowHideProps} />

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  pageTitleContainer: {
    height: "250px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(#E5EAFF, #EFF2FF)"
  },
  pageTitle: {
    fontFamily: "Lato",
    fontSize: "48px",
    fontWeight: 800,
    lineHeight: "67.2px",
    letterSpacing: "0.02em",
    color: "#000",
    textAlign: "center",
  },
  pageDescription: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.4px",
    color: "#595959",
    textAlign: "center",
    marginTop: "10px"
  }
};
// Customizable Area End
