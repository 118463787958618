import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import ReactApexChart from "react-apexcharts";
import { styled } from "@material-ui/core/styles";
import {
  Typography as MUITypography,
  Box,
  Grid,
} from "@material-ui/core";
import CustomDropDown from "./CustomDropDown";
import LogEmissonMessage from "./LogEmissionMessage";
import SwitchButton from "../../dashboard/src/common/SwitchButton.web";

const ChartTitle = styled(MUITypography)({
  fontFamily: "Lato",
  fontSize: "22px",
  fontWeight: 700,
  lineHeight: "27.5px",
  color: "#34373A"
});

const EnterTextLabel = styled(MUITypography)({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "19.2px",
  color: "#34373A",
  marginRight: "5px",
});

const CustomGrid = styled(Grid)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    "@media all and (max-width: 600px)": {
      justifyContent: "flex-start",
    }
});

const SwitchContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    position: "absolute",
    top: "64px",
    right: "95px",
    "@media all and (max-width: 600px)": {
      top: "108px",
    }
});

interface ChangeProps{
  name?: string;
  value: unknown;
}

// Customizable Area End

import Cfmaterialitymatrixgraph0Controller, {
  Props,
  configJSON,
} from "./Cfmaterialitymatrixgraph0Controller";

export default class Cfmaterialitymatrixgraph0 extends Cfmaterialitymatrixgraph0Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="materiality-matrix-chart" sx={{ padding: "0px 54px", position: "relative" }}>
        <Grid container alignItems="center" spacing={2} style={{ marginBottom: "15px" }}>
          <Grid item sm={6} xs={12}>
            <ChartTitle>Materiality Matrix</ChartTitle>
          </Grid>
          <CustomGrid item sm={6} xs={12}>
            <CustomDropDown
              data-test-id="framework-select"
              width="110px"
              value={`${this.state.frameworkId}`}
              onChange={(event: React.ChangeEvent<ChangeProps>) => 
                this.setState({frameworkId: event.target.value as number})
              }
              options={this.state.frameworks}
            />
            <CustomDropDown
              data-test-id="year-select"
              width="65px"
              value={`${this.state.yearId}`}
              onChange={(event: React.ChangeEvent<ChangeProps>) => 
                this.setState({yearId: event.target.value as number})
              }
              options={this.state.years}
            />
          </CustomGrid>
        </Grid>
        {
          this.state.showLogEmissionMessage ?
            <LogEmissonMessage 
              data-test-id="log-emission-msg" 
              onLogButtonClick={this.props.navigateToMaterialCards} 
            /> :
            <>
              <ReactApexChart
                data-test-id="materiality-matrix-chart"
                options={this.state.bubbleChartOptions}
                series={this.state.bubbleChartData.series}
                type="bubble"
                height={433}
              />
              <SwitchContainer>
                <EnterTextLabel>Enable Labels</EnterTextLabel>
                <SwitchButton 
                  id={0} 
                  index={0} 
                  removeMarginRight
                  checked={this.state.showDataLables} 
                  handleChange={this.handleShowHideDataLables} 
                  data-test-id="label-switch"
                />
              </SwitchContainer>
            </>
        }
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
