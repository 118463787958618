import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ReactApexChart from "react-apexcharts";
import Layout from "../../dashboard/src/Layout.web";

const SERIES = [
  {
    name: "Planned",
    data: [
      {
        x: "GHG Inventory",
        y: [
          new Date("2024-11-01").getTime(),
          new Date("2025-01-31").getTime(),
        ],
      },
      {
        x: "Emission Categorization",
        y: [
          new Date("2024-11-01").getTime(),
          new Date("2025-03-31").getTime(),
        ],
      },
      {
        x: "Something",
        y: [
          new Date("2024-11-01").getTime(),
          new Date("2025-02-21").getTime(),
        ],
      },
    ],
  },
  {
    name: "Actual",
    data: [
      {
        y: [
          new Date("2024-11-01").getTime(),
          new Date("2024-12-31").getTime(),
        ],
        x: "GHG Inventory",
      },
      {
        y: [
          new Date("2024-11-01").getTime(),
          new Date("2025-03-31").getTime(),
        ],
        x: "Emission Categorization",
      },
      {
        y: [
          new Date("2024-11-01").getTime(),
          new Date("2025-12-05").getTime(),
        ],
        x: "Something",
      },
    ],
  },
];
// Customizable Area End

import GanttChart3Controller, {
  Props,
  configJSON,
} from "./GanttChart3Controller";

export default class GanttChart3 extends GanttChart3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout>
        <br />
        <br />
        <ReactApexChart
          data-test-id="timeline-chart"
          options={configJSON.ganttChartOptions}
          series={SERIES}
          type="rangeBar"
          height={433}
        />
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
