import React from "react";

import {
  // Customizable Area Start
  Typography,
    IconButton,
    Tooltip,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Modal, Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles, styled } from '@material-ui/core/styles';
import { Framework, info } from "../assets";

export const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFF',
        border: 'none',
        boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11)",
        maxWidth: "200px",
        display: "inline-flex",
        padding: "7px 10px 7px 16px",
        justifyContent: "flex-end",
        alignItems: "center",
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
    },
    arrow: {
        color: '#FFF',
    },
}))(Tooltip);

const framworks = {
    title: "Report Title",
    description: "Description",
    action: "Action",
};

const FramworkTableContainer = styled(TableContainer)({
    minHeight: "583px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: '26px',
    padding: '12px'
});

const FramworkTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
});

const FramworkTableBody = styled(TableBody)({
    color: "inherit",
});

const FramworkSpacing = styled("div")({
    height: 16,
    display: "table-header-group",
});

const FramworkTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "8px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const FramworkTableCellHeadTitle = styled(TableCell)({
    minHeight: "48px",
    padding: "8px 18px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    minWidth: '200px'
});

const FramworkTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const FramworkTableCellTitle = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px 8px 8px 18px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
    minWidth: '200px'
});

const FramworkTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
});

const FramworkTableRowHead = styled(TableRow)({
    borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});

const Image = styled("img")({
    "@media(max-width: 980px)": {
        width: 700
    },
    "@media(max-width: 768px)": {
        width: 600
    },
    "@media(max-width: 620px)": {
        width: 500
    },
    "@media(max-width: 520px)": {
        width: 300
    },
});

const framworksData = [
    {
        "title": "GRI (1997)",
        "description": "Global Standard for tracking and communicating ESG performance"
    },
    {
        "title": "GHGP (1998)",
        "description": "Framework for measuring and managing GHG emissions"
    },
    {
        "title": "CDP (2002)",
        "description": "Platform for companies to report their carbon emissions, climate risks, and strategies for reducing their carbon footprint"
    },
    {
        "title": "CDSB (2007)",
        "description": "Framework for reporting on climate-related risks & opportunities in mainframe financial reports"
    },
    {
        "title": "SASB (2011)",
        "description": "Industry-specific standards for reporting on sustainability issues for informed investment decisions"
    },
    {
        "title": "IR (2013)",
        "description": "Framework for reporting on organization value creation strategy performance and prospects in a holistic and integrated manner"
    },
    {
        "title": "PCAF (2015)",
        "description": "Framework for financial institutions to measure and disclose carbon emissions associated with their investment portfolio"
    },
    {
        "title": "TCFD (2015)",
        "description": "Framework for reporting on climate-related risks & opportunities"
    },
    {
        "title": "SBTI (2015)",
        "description": "Helps set GHG targets with the goals of the Paris Agreement"
    },
    {
        "title": "VRF (2021)",
        "description": "Incorporates both financial and non-financial performance matrix"
    },
    {
        "title": "ISSB (2021)",
        "description": "Aims to unify voluntary reporting and allow transparent comparison between companies and investors"
    }
]
// Customizable Area End

import Multiplelandingpage2Controller, {
  Props,
  configJSON,
} from "../Multiplelandingpage2Controller";

export default class ReportsFramwork extends Multiplelandingpage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
                <Modal open={this.state.open} style={webStyle.modalStyle} onClose={this.handleClose} data-test-id="modalClose">
                    <Paper style={webStyle.paperStyle}>
                        <FramworkTableContainer>
                            <FramworkTable aria-label="users table">
                                <FramworkTableHead>
                                    <FramworkTableRowHead>
                                        <FramworkTableCellHeadTitle>
                                            {framworks.title}
                                        </FramworkTableCellHeadTitle>
                                        <FramworkTableCellHead>{framworks.description}</FramworkTableCellHead>
                                    </FramworkTableRowHead>
                                </FramworkTableHead>
                                <FramworkSpacing />
                                <FramworkTableBody>
                                    {framworksData && framworksData.map((user: any, index: number) => {
                                        return (
                                            <TableRow>
                                                <FramworkTableCellTitle style={{ minWidth: 100 }}>
                                                    {user.title}
                                                </FramworkTableCellTitle>
                                                <FramworkTableCell style={{ minWidth: 300 }}>
                                                    {user.description}
                                                </FramworkTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </FramworkTableBody>
                            </FramworkTable>
                        </FramworkTableContainer>
                    </Paper>
                </Modal>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={webStyle.title}>Reporting Framework</Typography>
                    <CustomTooltip placement="bottom-end"
                        title="Description of what the tooltip is about and what user should do to understand the tile"
                        arrow>
                        <IconButton style={{ marginTop: '10px' }}><img src={info} alt="info" /></IconButton>
                    </CustomTooltip>
                </div>
                <Typography style={webStyle.description}>
                    Explanation on the framework and why we picked this one along with instructions on hover.{" "}
                    <span onClick={this.handleShowDetails} data-test-id="showdetails" style={webStyle.link}>{" "}Please click</span> here to know more information about reporting standards.
                </Typography>
                <div style={webStyle.maintasklist}>
                    <Image src={Framework} />
                </div>
            </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    title: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "125%",
        margin: '32px 0 23px 0'
    },
    description: {
        color: "#7D7D7F",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        maxWidth: '882px',
        marginBottom: '33px'
    },
    mainlistcontainer: {
        width: '100%',
        minHeight: '88px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    maintasklist: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '85px',
        marginBottom: '43px'
    },
    link: {
        color: "#2563EB",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "160%",
        maxWidth: '882px',
        marginBottom: '33px',
        textDecorationLine: "underline",
        cursor: "pointer"
    },
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paperStyle: {
        width: '680px',
        maxWidth: "100%",
        padding: '20px',
        borderRadius: '8px',
        maxHeight: '630px',
        overflow: 'auto'
    },
};
// Customizable Area End
