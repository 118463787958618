import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  styled,
  TextField,
  Typography as TypographyMUI,
  Button as ButtonMUI,
  withStyles,
  Tooltip,
  CircularProgress,
  Box
} from "@material-ui/core";
import { Formik, Form, FieldArray } from "formik";
import Layout from "../../dashboard/src/Layout.web";
import InputRows from "../../../components/src/InputRows";
import { infoIcon } from "./assets";
import {InputRow} from "./CfclimatiqshippingfreightController";
import FailureDialog from "../../cfclimatiqbasicestimate/src/components/FailureDialog";
import SuccessDialog from "../../cfclimatiqbasicestimate/src/components/SuccessDialog";
import Activity from "../../cfclimatiqbasicestimate/src/components/Activity";

const Container = styled("div")({
  width: "100%",
  padding: "32px 44px 20px 44px"
});

const PageContent = styled("div")({
  marginBottom: "23px"
});

const HeadingRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "17px"
});

const MainHeading = styled(TypographyMUI)({
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "27.5px"
});

const CustomButton = styled(ButtonMUI)({
  minHeight: "40px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#43D270",
  color: "#34373A",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  '& span': {
    fontFamily: "Inter",
    fontWeight: 700,
  }
});

const DisabledButton = styled(CustomButton)({
  pointerEvents: "none",
  background: "#B1B3C3",
  "&:hover": {
    background: "#B1B3C3"
  }
});

const Text = styled(TypographyMUI)({
  color: "#7D7D7F",
  textAlign: "left",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "19.2px"
});

const TextInput = styled(TextField)({
  width: '100%',
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  marginBottom: 40,
  '& *': {
    borderColor: "rgba(0, 0, 0, 0.2) !important",
  },
});

const LabelStyled = styled(TypographyMUI)({
  fontSize: "15px",
  fontFamily: "Lato",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "8px"
});

const AddMoreButton = styled(ButtonMUI)({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "19.2px",
  textAlign: "right",
  color: "#43D270",
  textTransform: "capitalize",
  margin: "10px 0 30px",
});

const AddButton = styled(ButtonMUI)({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "19.2px",
  textAlign: "right",
  color: "#43D270",
  textTransform: "capitalize",
  marginBottom: "10px",
});

const TooltipContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const InfoIcon = styled("img")({
  height: "16px",
  width: "16px",
  marginLeft: "10px",
  marginBottom: "8px",
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#7D7D7F",
    width: "274.427px",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
    display: "inline-flex",
    height: "71px",
    padding: "7px 0px 7px 16.048px",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    background: "#FFF",
    boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11)"
  },
}))(Tooltip);

const TextResult = styled(TextInput)({
  width: "404px",
  "@media all and (max-width: 600px)": {
    width: "100%"
  }
});

const CircularProgressContainer = styled("div")({
  height: "calc(100vh - 145px)", 
	color: "grey", 
  display: "flex", 
  justifyContent: "center",
  alignItems: "center", 
});

const ButtonBox = styled(Box)({
  display:'flex',
  flexDirection:'column'
});
const AddButtonBox = styled(Box)({
  textAlign:'end',
  borderBottom: "1px solid #EBEBEB",
  width:'100%'
})
const AddMoreBox = styled(Box)({
  textAlign:'start'
})

const tooltipDescription = "Total CO2 emissions generated by the activity, measured in kilograms";

// Customizable Area End

import CfclimatiqshippingfreightController, {
  Props,
  configJSON,
} from "./CfclimatiqshippingfreightController";

export default class Cfclimatiqshippingfreight extends CfclimatiqshippingfreightController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout>
        {
          this.state.isLoading ? (
            <CircularProgressContainer>
              <CircularProgress color="inherit" />
            </CircularProgressContainer>
          ) : (
            <Container style={{ pointerEvents: this.state.hasSavedMetrics ? "none" : "auto" }}>
              <Formik
              data-test-id="formiktest"
                enableReinitialize
                initialValues={{
                  inputRows: this.state.inputRows
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values: {inputRows:InputRow[]}) => this.handleCaluculate(values)}
              >
                {formikProps => {
                  const enableSaveButton = formikProps.isValid && !this.state.hasSavedMetrics && this.state.co2Result;
                  return (
                    <>
                      <PageContent>
                        <HeadingRow>
                          <MainHeading>{this.state.pageTitle}</MainHeading>
                          {enableSaveButton ?
                          <CustomButton onClick={this.handleSave} data-test-id="save_button">Save</CustomButton>
                            :
                            <DisabledButton data-test-id="save_button" >Save</DisabledButton>
                          }
                        </HeadingRow>
                        <Text>{this.state.pageDescription}</Text>
                      </PageContent>
                      <Form>
                        <FieldArray
                          name="inputRows"
                          data-testid="feild-array"
                          render={arrayHelpers => {
                            return (
                              <>
                                <InputRows
                                  data-testid="input-rows"
                                  createLabel={this.generateLabelFromParamName}
                                  createOptions={this.generateOptionsFromParamName}
                                  handleCallVehicleWeight={this.handleCallVehicleWeight}
                                  handleCallVehicleOption = {this.handleCallVehicleOption}
                                  handleCallTonnageOption= {this.getTonnageAPICall}
                                  handleCallSeaFuelOption={this.getSeaFuelAPICall}
                                  handleTransportModeValue={this.handleTransportModeValue}
                                  deleteTransportMode={(rowIndex) => arrayHelpers.remove(rowIndex)}
                                />
                                      <>
                                      <ButtonBox>
                                      <AddButtonBox>
                                      <AddButton
                                      type="button"
                                      data-test-id="add-another-item-btn"
                                      onClick={() => arrayHelpers.push(this.initialTransportRow)}
                                      >
                                        {configJSON.addMore}
                                      </AddButton>
                                      </AddButtonBox>
                                      <AddMoreBox>
                                      <AddMoreButton
                                        type="button"
                                        data-test-id="add-another-item-btn"
                                        onClick={() => arrayHelpers.push(this.initialRow)}
                                      >
                                        + Add Another Item
                                      </AddMoreButton>
                                      </AddMoreBox>
                                      </ButtonBox>
                                      <br />
                                      <CustomButton
                                      data-testid="calculate-button"
                                      style={{ width: "207px", height: "40px", margin: "40px 0" }}
                                      type="submit"
                                      >
                                        Calculate
                                      </CustomButton>  
                                      </>
                                      </>
                            );
                          }}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
              <TooltipContainer>
                <LabelStyled>Resulting CO2 in kgs</LabelStyled>
                <LightTooltip title={tooltipDescription}>
                  <InfoIcon src={infoIcon} alt="info icon" />
                </LightTooltip>
              </TooltipContainer>
              <TextResult
                data-test-id="co2Result"
                id="outlined-basic"
                variant="outlined"
                value={this.state.co2Result}
                inputProps={{
                  style: { fontSize: "15px" }
                }}
                InputProps={{
                  style: {
                    height: "50px",
                    borderRadius: "8px",
                  }
                }}
              />
              <Activity 
                comment={this.state.comment}
                onComment={this.handleCommentChange}
                activities={this.state.userActivites}
              />
              <FailureDialog 
                open={this.state.openFailureDialog}
                onClose={this.handleCloseFailureDialog}
                message={this.state.failureMessage}
              />
              <SuccessDialog 
                open={this.state.openSuccessDialog}
                onClose={this.handleCloseSuccessDialog}
                onContactUsClick={this.navigateToContactUs}
              />
            </Container>
          )
        }    

      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
