import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  Button
} from "@material-ui/core";
import { blurMaterialMatrix } from "./assets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overviewbg: {
      minHeight: '642px',
      backgroundImage: `url(${blurMaterialMatrix})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    insideModel: {
      borderRadius: "12px", padding: '27px',
      marginTop: '40px', marginLeft: '0px',
      background: "#ECFBF1",
    },
    modelTitle: {
      color: "#101010",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      lineHeight: "120%",
      fontWeight: 600,
    },
    modelDesc: {
      color: "#34373A",
      fontStyle: "normal",
      fontSize: "15px",
      fontWeight: 400,
      fontFamily: "Lato",
      lineHeight: "160%",
      marginTop: '12px'
    },
    logbutton: {
      width: "187px",
      height: "40px",
      display: "block",
      padding: "7px 0",
      borderRadius: "8px",
      color: "#34373A",
      background: "#43D270",
      textAlign: "center",
      textTransform: "capitalize",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 700,
      fontStyle: "normal",
      lineWeight: "160%",
      textDecoration: 'none',
      marginTop: '22px',
  },
  })
);

interface Props {
  onLogButtonClick: () => void;
}

export default function LogEmissonMessage(props: Props) {
  const classes = useStyles();

  return (
    <div data-test-id="log-emission-message-container" className={classes.overviewbg}>
      <div className={`${classes.insideModel} insideModel`}>
          <Typography className={classes.modelTitle}>Log your emissions to track</Typography>
          <Typography className={classes.modelDesc}>
            Data for Materiality Matrix comes from your log entries. 
            It seems like your organisation didn't log enough data for us to showcase any insights. 
            Please enter log data in order to leverage CarbonZeroed full potential. 
          </Typography>
          <Button 
              data-test-id="log-your-first-em-btn"
              onClick={props.onLogButtonClick}
              className={classes.logbutton}
          >
              + Log Your First Emission
          </Button>
      </div>
  </div>
  );
}