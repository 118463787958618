import React from "react";
import {
  styled,
  FormControl,
  TextField,
  Grid,
  Typography as TypographyMUI,
  Paper
} from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import SelectFieldStyled from "../../blocks/dashboard/src/common/SelectFieldStyled.web";
import {deleteIcon} from "../../blocks/cfclimatiqshippingfreight/src/assets"
import Autocomplete from "@material-ui/lab/Autocomplete";

interface Option {
  id: number;
  value: string;
  label: string;
}

interface Props {
  createLabel: (paramName: string) => string;
  createOptions: (paramName: string) => Option[];
  handleCallVehicleWeight:(vehicleValue:string) => void;
  handleCallVehicleOption:(vehicleWeight:string) => void;
  handleCallTonnageOption:(vesselValue:string) => void;
  handleCallSeaFuelOption:(tonnageVale:string) => void;
  deleteTransportMode:(rowIndex:number) => void;
  handleTransportModeValue:(transportValue:string) => void;
}

interface Target {
  name?: string;
  value: string;
}

interface Input {
  type: string;
  name: string;
  value: string;
}

interface Inputs {
  origin: Input;
  destination: Input;
  weight: Input;
  weight_unit: Input;
  transport_mode: Input;
}

interface InputRow {
  id: string;
  inputs: Inputs;
}

interface InputRowsType {
  inputRows: InputRow[];
}

const CustomPaper = styled(Paper)({
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  background: "#FFF",
  maxHeight: '250px',
});
const ErrorAlert = styled("div")({
  color: "#ff0000",
  lineHeight: 1,
  marginTop: "10px",
  fontFamily: "Lato",
  fontSize: "15px",
  height:'10px'
});



export default function InputRows(props: Props) {
  const { values, handleChange, touched, errors, setFieldValue, setValues } = useFormikContext<InputRowsType>();

  const handleDropdownChange = (
    event: React.ChangeEvent<Target>,
    name: string,
    label: string,
    rowIndex: number,
  ) => {
    if (label === "Transport Mode") {
      const { value } = event.target;
      props.handleTransportModeValue(event.target.value);
      const currentValues = JSON.parse(JSON.stringify(values));
      switch (value) {
        case "rail":
          // Remove air options
          delete currentValues.inputRows[rowIndex].inputs["aircraft_type"];
          delete currentValues.inputRows[rowIndex].inputs["methodology"];
          delete currentValues.inputRows[rowIndex].inputs["vehicle_type"];
          delete currentValues.inputRows[rowIndex].inputs["vehicle_weight"];
          delete currentValues.inputRows[rowIndex].inputs["fuel_source"];
          delete currentValues.inputRows[rowIndex].inputs["road_load_type"];
          delete currentValues.inputRows[rowIndex].inputs["vessel_type"]
          delete currentValues.inputRows[rowIndex].inputs["tonnage"]
          delete currentValues.inputRows[rowIndex].inputs["sea_fuel_source"]
          delete currentValues.inputRows[rowIndex].inputs["sea_load_type"]

          currentValues.inputRows[rowIndex].inputs["transport_mode"] = { type: "dropdown", name: "transport_mode", value };
          currentValues.inputRows[rowIndex].inputs["fuel_type"] = { type: "dropdown", name: "fuel_type", value: "" };
          currentValues.inputRows[rowIndex].inputs["load_type"] = { type: "dropdown", name: "load_type", value: "" };
          setValues(currentValues);
          break;
        case "air":
          // Remove rail options
          delete currentValues.inputRows[rowIndex].inputs["fuel_type"]
          delete currentValues.inputRows[rowIndex].inputs["load_type"]
          delete currentValues.inputRows[rowIndex].inputs["vehicle_type"]
          delete currentValues.inputRows[rowIndex].inputs["vehicle_weight"]
          delete currentValues.inputRows[rowIndex].inputs["fuel_source"]
          delete currentValues.inputRows[rowIndex].inputs["road_load_type"]
          delete currentValues.inputRows[rowIndex].inputs["vessel_type"]
          delete currentValues.inputRows[rowIndex].inputs["tonnage"]
          delete currentValues.inputRows[rowIndex].inputs["sea_fuel_source"]
          delete currentValues.inputRows[rowIndex].inputs["sea_load_type"]

          currentValues.inputRows[rowIndex].inputs["transport_mode"] = { type: "dropdown", name: "transport_mode", value };
          currentValues.inputRows[rowIndex].inputs["aircraft_type"] = { type: "dropdown", name: "aircraft_type", value: "" };
          currentValues.inputRows[rowIndex].inputs["methodology"] = { type: "dropdown", name: "methodology", value: "" };
          setValues(currentValues);
          break;
        case "road":
          delete currentValues.inputRows[rowIndex].inputs["fuel_type"]
          delete currentValues.inputRows[rowIndex].inputs["load_type"]
          delete currentValues.inputRows[rowIndex].inputs["aircraft_type"];
          delete currentValues.inputRows[rowIndex].inputs["methodology"];
          delete currentValues.inputRows[rowIndex].inputs["vessel_type"]
          delete currentValues.inputRows[rowIndex].inputs["tonnage"]
          delete currentValues.inputRows[rowIndex].inputs["sea_fuel_source"]
          delete currentValues.inputRows[rowIndex].inputs["sea_load_type"]

          currentValues.inputRows[rowIndex].inputs["transport_mode"] = { type: "dropdown", name: "transport_mode", value };
          currentValues.inputRows[rowIndex].inputs["vehicle_type"] = { type: "dropdown", name: "vehicle_type", value: "" };
          currentValues.inputRows[rowIndex].inputs["vehicle_weight"] = { type: "dropdown", name: "vehicle_weight", value: "" };
          currentValues.inputRows[rowIndex].inputs["fuel_source"] = { type: "dropdown", name: "fuel_source", value: "" };
          currentValues.inputRows[rowIndex].inputs["road_load_type"] = { type: "dropdown", name: "road_load_type", value: "" };
          setValues(currentValues);
          break;
        case "sea":
            delete currentValues.inputRows[rowIndex].inputs["fuel_type"]
            delete currentValues.inputRows[rowIndex].inputs["load_type"]
            delete currentValues.inputRows[rowIndex].inputs["aircraft_type"];
            delete currentValues.inputRows[rowIndex].inputs["methodology"];
            delete currentValues.inputRows[rowIndex].inputs["vehicle_type"]
            delete currentValues.inputRows[rowIndex].inputs["vehicle_weight"]
            delete currentValues.inputRows[rowIndex].inputs["fuel_source"]
            delete currentValues.inputRows[rowIndex].inputs["road_load_type"]
           
            currentValues.inputRows[rowIndex].inputs["transport_mode"] = { type: "dropdown", name: "transport_mode", value };
            currentValues.inputRows[rowIndex].inputs["vessel_type"] = { type: "dropdown", name: "vessel_type", value: "" };
            currentValues.inputRows[rowIndex].inputs["tonnage"] = { type: "dropdown", name: "tonnage", value: "" };
            currentValues.inputRows[rowIndex].inputs["sea_fuel_source"] = { type: "dropdown", name: "sea_fuel_source", value: "" };
            currentValues.inputRows[rowIndex].inputs["sea_load_type"] = { type: "dropdown", name: "sea_load_type", value: "" };
            setValues(currentValues);
            break;
        default:
          break;
      }
    }else {
      switch (label) {
        case "Vehicle Type":
          setFieldValue(name, event.target.value);
          props.handleCallVehicleWeight(event.target.value);
          break;
        case "Vehicle Weight":
          setFieldValue(name, event.target.value);
          props.handleCallVehicleOption(event.target.value);
          break;
        case "Vessel Type":
          setFieldValue(name, event.target.value);
          props.handleCallTonnageOption(event.target.value);
          break;
        case "Tonnage":
            setFieldValue(name, event.target.value);
            props.handleCallSeaFuelOption(event.target.value);
            break;
        default:
          setFieldValue(name, event.target.value);
          break;
      }
    }
  }

  return (
    <>
      {values.inputRows.length > 0 && values.inputRows.map((inputRow, rowIndex) => {
        return (
          <FieldContainer>
            <Grid
              container
              spacing={2}
              alignItems="center"
              key={inputRow.id}
            >
              {
                Object.values(inputRow.inputs).map((input,inputIndex) => {
                  const label = props.createLabel(input.name);
                  const options = props.createOptions(input.name);
                  const name = `inputRows[${rowIndex}].inputs[${input.name}].value`;
                  if (input.type === "dropdown" && input.name === "origin") {
                    const value = {
                      id: inputIndex,
                      value: input.value,
                      label: input.value,
                    }
                    const errorMessage = getIn(errors, name)
                    return (
                      <Grid key={input.name} item xs={12} md={3} style={{marginBottom:'40px'}}>
                        <LabelStyled>{label}</LabelStyled>
                        <Autocomplete
                          disablePortal
                          // @ts-ignore
                          name={name}
                          options={options}
                          data-test-id="origin-autocomplete"
                          value={value}
                          onChange={(event, origin) => {
                            setFieldValue(
                              name,
                              origin !== null ? origin.value : ""
                            );
                          }}
                          PaperComponent={CustomPaper}
                          closeIcon={false}
                          popupIcon={<div style={{ margin: '0px 12px' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <path d="M15 7.5L10 12.5L5 7.5" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                          </div>}
                          PopperComponent={({ children, ...other }) => (
                              <div {...other} style={{
                                maxHeight: '271px',
                                borderRadius: "8px",
                                border: "1px solid #D9D9D9",
                                background: "#FFF",
                                maxWidth: '404px',
                                boxShadow: 'none !important',
                                padding: '0px !important',
                                zIndex: 1,
                                position: 'absolute'
                              }}>
                                  {children}
                              </div>
                          )}
                          getOptionLabel={option => option.label}
                          renderOption={option => option.label}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Select"
                              inputProps={{
                                ...params.inputProps,
                                style: { 
                                  fontSize: "15px",
                                  padding: "2px 0" 
                                }
                              }}
                              InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  style: {
                                    height: "50px",
                                    maxWidth: '404px',
                                    borderRadius: '8px',
                                    border: "1px solid #D9D9D9",
                                    padding: '12px',
                                    boxShadow: 'none'
                                  },
                              }}
                              FormHelperTextProps={{
                                style: {
                                  color: "#ff0000",
                                  lineHeight: 1,
                                  margin: "10px 0 0 0",
                                  fontFamily: "Lato",
                                  fontSize: "15px"
                                }
                              }}
                            />
                          )}
                        />
                        {getIn(touched, name) ? <ErrorAlert>{errorMessage}</ErrorAlert> : <ErrorAlert>{}</ErrorAlert>}
                      </Grid>
                    );
                  } else if (input.type === "dropdown" && input.name === "destination") {
                    const value = {
                      id: inputIndex,
                      value: input.value,
                      label: input.value,
                    }
                    const errorMessage = getIn(errors, name)
                    return (
                      <Grid key={input.name} item xs={12} md={3} style={{marginBottom:'40px'}}>
                        <LabelStyled>{label}</LabelStyled>
                        <Autocomplete
                          disablePortal
                          // @ts-ignore
                          name={name}
                          options={options}
                          data-test-id="destination-autocomplete"
                          value={value}
                          onChange={(event, destination) => {
                            setFieldValue(
                              name,
                              destination !== null ? destination.value : ""
                            );
                          }}
                          PaperComponent={CustomPaper}
                          closeIcon={false}
                          popupIcon={<div style={{ margin: '0px 12px' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <path d="M15 7.5L10 12.5L5 7.5" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                          </div>}
                          PopperComponent={({ children, ...other }) => (
                              <div {...other} style={{
                                maxHeight: '271px',
                                borderRadius: "8px",
                                border: "1px solid #D9D9D9",
                                background: "#FFF",
                                maxWidth: '404px',
                                boxShadow: 'none !important',
                                padding: '0px !important',
                                zIndex: 1,
                                position: 'absolute'
                              }}>
                                  {children}
                              </div>
                          )}
                          getOptionLabel={option => option.label}
                          renderOption={option => option.label}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Select"
                              inputProps={{
                                ...params.inputProps,
                                style: { 
                                  fontSize: "15px",
                                  padding: "2px 0" 
                                }
                              }}
                              InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  style: {
                                    height: "50px",
                                    maxWidth: '404px',
                                    borderRadius: '8px',
                                    border: "1px solid #D9D9D9",
                                    padding: '12px',
                                    boxShadow: 'none'
                                  },
                              }}
                              FormHelperTextProps={{
                                style: {
                                  color: "#ff0000",
                                  lineHeight: 1,
                                  margin: "10px 0 0 0",
                                  fontFamily: "Lato",
                                  fontSize: "15px"
                                }
                              }}
                            />
                          )}
                        />
                        {getIn(touched, name) ? <ErrorAlert>{errorMessage}</ErrorAlert> : <ErrorAlert>{}</ErrorAlert>}
                      </Grid>
                    );
                  } else if (input.type === "dropdown") {
                    return (
                      <Grid key={input.name} item xs={12} md={3}>
                        <SelectFieldStyled
                          noFixedWidth
                          name={name}
                          value={input.value}
                          onChange={(event: React.ChangeEvent<Target>) => handleDropdownChange(event, name, label, rowIndex)}
                          options={options}
                          label={label}
                          placeholder="Select"
                          defaultSelectLabel="Select"
                          error={
                            getIn(touched, name) &&
                            getIn(errors, name)
                          }
                          errorMessage={
                            getIn(touched, name) &&
                            getIn(errors, name) 
                          }
                        />
                      </Grid>
                    );
                  } else if (input.type === "text") {
                    return (
                      <Grid key={input.name} item xs={12} md={3} style={{marginBottom:'40px'}}>
                        <LabelStyled>Weight</LabelStyled>
                        <TextInput
                          id="outlined-basic"
                          variant="outlined"
                          name={name}
                          value={input.value}
                          onChange={handleChange}
                          placeholder="Write weight"
                          error={
                            getIn(touched, name) &&
                            getIn(errors, name)
                          }
                          inputProps={{
                            style: { fontSize: "15px" }
                          }}
                          InputProps={{
                            style: {
                              height: "50px",
                              borderRadius: "8px",
                            }
                          }}
                          FormHelperTextProps={{
                            style: {
                              color: "#ff0000",
                              lineHeight: 1,
                              margin: "10px 0 0 0",
                              fontFamily: "Lato",
                              fontSize: "15px"
                            }
                          }}
                        />
                         {getIn(touched, name) ? <ErrorAlert>{getIn(errors, name)}</ErrorAlert> : <ErrorAlert>{}</ErrorAlert>}
                      </Grid>
                    );
                  }
                })
              }
              <Grid item xs={12} md={3}>
                    <img src={deleteIcon} alt="delete" 
                    onClick={() => props.deleteTransportMode(rowIndex)}
                    style={{ display: (values.inputRows.length === 1 || rowIndex === 0) ? "none" : "block",marginBottom:'25px' }}
                    />
                  </Grid>
            </Grid>
          </FieldContainer>
        )
      }
      )}
    </>
  );
}

const TextInput = styled(TextField)({
  width: '100%',
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  '& *': {
    borderColor: "rgba(0, 0, 0, 0.2) !important",
  },
});

const LabelStyled = styled(TypographyMUI)({
  fontSize: "15px",
  fontFamily: "Lato",
  fontWeight: 400,
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#34373A",
  marginBottom: "8px"
});

const FieldContainer = styled(FormControl)({
  width: "100%",
});